import axiosInstance from '@/axios'
import {createEqualParam, createParams} from "@/utils/searchQueryParamBuilder"
import {generateFilterQuery} from "@/utils/rsql"
import {isEmpty, map, values} from "lodash"

const defaultSort = {
    proposalId: {
        sortingPath: 'proposal.id',
        sortType: 'DESC',
    },
    id: {
        sortingPath: 'id',
        sortType: 'DESC',
    }
}

const hiddenAndFavoritesDefaultSort = {
    dateAdded: {
        sortingPath: 'dateAdded',
        sortType: 'DESC'
    }
}

export const getPurchases = ({url, profileId, page, customFilters, currentTab, isActiveSearch, size, sort, filterId}) => {

    const sortBy = isEmpty(sort) ?
        (currentTab.filterType === 'FAVORITES' || currentTab.filterType === 'HIDDEN')
            ? hiddenAndFavoritesDefaultSort
            : defaultSort
        : sort

    return axiosInstance.post(
        `api/collection${url}${createParams(
            createEqualParam('profileId', profileId),
            createEqualParam('page', page),
            createEqualParam('size', size),
            createEqualParam('isActiveSearch', currentTab.filterType === 'FAVORITES' || currentTab.filterType === 'HIDDEN' ? false : isActiveSearch),
            createEqualParam('filterId', filterId),
            ...map(values(sortBy), it => createEqualParam('sort', `${it.sortingPath},${it.sortType}`))
            )}`,
        {
            query: generateFilterQuery(customFilters, currentTab.searchCriteria)
        },
        {
            headers:{
                'Content-Type' : 'application/json'
            }
        }
    )
}

export const getAnotherLots = (lotId) => {

    return axiosInstance.get(`/api/collection/anotherLots/${lotId}`)
}

export const createFavoriteLot = (favoriteLotData) => {

    return axiosInstance.post(`/api/profile/favoriteLots?projection=favoriteLotProjection`, favoriteLotData)
}

export const removeFavoriteLot = (favoriteLotId) => {

    return axiosInstance.delete(`/api/profile/favoriteLots/${favoriteLotId}`)
}

export const addLotToHidden = (hiddenLotData) => {
    return axiosInstance.post(`/api/profile/hiddenLots`, hiddenLotData)
}

export const removeLotFromHidden = (hiddenLotId) => {
    return axiosInstance.delete(`/api/profile/hiddenLots/${hiddenLotId}`)
}
