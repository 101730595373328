import {
    SET_COLUMNS_FILTERS,
    ADD_COLUMN_FILTER,
    REMOVE_COLUMN_FILTER,
    REMOVE_COLUMN_FILTERS,
}  from "./mutation-types"
import {RESET} from "../../mutation-types"

const actions = {

    setColumnsFilters: ({commit}, columnsFilters) => {
        commit(SET_COLUMNS_FILTERS, columnsFilters)
    },

    addColumnFilter: ({commit}, payload) => {
        commit(ADD_COLUMN_FILTER, payload)
    },

    removeColumnFilter: ({commit}, payload) => {
        commit(REMOVE_COLUMN_FILTER, payload)
    },

    removeColumnFilters: ({commit}, field) => {
        commit(REMOVE_COLUMN_FILTERS, field)
    },

    resetColumnsFilters: ({commit}) => {
        commit(RESET)
    }
}

export default actions