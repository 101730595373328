import axiosInstance from "@/axios"
import grantTypes from "../../utils/constants/grantTypes"
import Configuration from "@/configuration"

const loginUser = (credentials) => {

    const config = {
        params: {
            'grant_type': grantTypes.password,
            'username': credentials.email,
            'password': credentials.password
        },
        auth: {
            username: Configuration.value('clientName'),
            password: Configuration.value('clientSecret'),
        },
    }

    const data = {}

    return axiosInstance.post(`/oauth/token`, data, config)
}

const refreshTokens = (refreshToken) => {
    const config = {
        params: {
            'grant_type': grantTypes.refresh_token,
            'refresh_token': refreshToken
        },
        auth: {
            username: Configuration.value('clientName'),
            password: Configuration.value('clientSecret'),
        },
    }

    const data = {}

    return axiosInstance.post(`/oauth/token`, data, config)
}

const recoveryPassword = (email) => {
    
    return axiosInstance.post(`/oauth/password/reset`, email)
}

const changePassword = (user) => {
    
    return axiosInstance.post(`/oauth/password/change`, user)
}

const registerUser = (user) => {

    return axiosInstance.post(`/oauth/registration`, user)
}

export {
    loginUser,
    refreshTokens,
    recoveryPassword,
    changePassword,
    registerUser,
}

