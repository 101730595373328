import axiosInstance from '@/axios'
import axios from 'axios';

let CancelToken = axios.CancelToken;
let source = CancelToken.source();

const reinitCancelToken = () => {
  source = CancelToken.source()
}

const getNotifications = (profileId) => {
    return axiosInstance.get(`/api/profile/notifications/${profileId}/all`);
  }

const getNotificationsNew = (profileId) => {
    return axiosInstance.get(`/api/profile/notifications/${profileId}/new`);
  }

const getNotificationsHistory = () => {
    return axiosInstance.get(`/api/profile/notifications/sent-history`);
  }

const sendNotification = (data) => {
    return axiosInstance.post(`/api/profile/notifications/create`, data);
  }

const markNotificationChecked = (profileId, data) => {
    return axiosInstance.patch(`/api/profile/notifications/mark-as-displayed?profileId=${profileId}`, data);
  }

const markNotificationReaded = (profileId, notificationId) => {
    return axiosInstance.patch(`/api/profile/notifications/mark-as-readed?profileId=${profileId}&notificationId=${notificationId}`);
  }

const getNotificationsDisplayed = async (profileId) => {
    return await axiosInstance.get(`/api/profile/notifications/popup?profileId=${profileId}`, {
      cancelToken: source.token
    })
  }

export {
  source,
  reinitCancelToken,
  getNotifications,
  getNotificationsNew,
  getNotificationsHistory,
  sendNotification,
  markNotificationChecked,
  markNotificationReaded,
  getNotificationsDisplayed
}