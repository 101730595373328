import axiosInstance from '@/axios'

const getFilterTabs = (profileId) =>
    axiosInstance.get(`/api/profile/profiles/${profileId}/filters?projection=filterProjection`)

const createFilterTab = (data) =>
    axiosInstance.post('/api/profile/filters', data)

const deleteFilterTab = (filterId) =>
    axiosInstance.delete(`/api/profile/filters/${filterId}`)

const editFilterTab = (filterId, data) =>
    axiosInstance.patch(`/api/profile/filters/${filterId}?projection=filterProjection`, data)

const updateAllFilterTabs = (filterTabs, profileId) =>
    axiosInstance.post(`/api/profile/filters/updateAll?profileId=${profileId}&projection=filterProjection`, filterTabs)

export {
    getFilterTabs,
    createFilterTab,
    deleteFilterTab,
    editFilterTab,
    updateAllFilterTabs,
}