import {
    GET_PURCHASES_REQUEST,
    GET_PURCHASES_REQUEST_SUCCESS,
    GET_PURCHASES_REQUEST_FAILURE,
    SET_FAVORITE_PURCHASE,
} from "./mutation-types"
import api from "@/application"
import { reload } from "../../../utils/constants/errorMessages"

const actions = {

    getPurchases: async ({commit}, searchParams) => {
        commit(GET_PURCHASES_REQUEST)
        try {
            const {data} = await api.purchases.getPurchases(searchParams)
            commit(GET_PURCHASES_REQUEST_SUCCESS, data)
        } catch (e) {
            commit(GET_PURCHASES_REQUEST_FAILURE)
            throw new Error(reload)
        }
    },

    setFavorite: ({commit}, favorites) => {
        commit(SET_FAVORITE_PURCHASE, favorites)
    },

}

export default actions
