import axiosInstance from '@/axios'

const getProceduresTypesEnum = () => axiosInstance.get('/api/collection/procedureTypes')

const getSourcesEnum = () => axiosInstance.get('/api/collection/sources')

const getSectorsEnum = () => axiosInstance.get('/api/collection/sectors')

const getPaginationSettings = () => axiosInstance.get('/api/profile/paginationSettings')

const getRowTypeSettings = () => axiosInstance.get('/api/profile/rowTypeSettings')

const getSubscriptionStatusesEnum = () => axiosInstance.get('/api/profile/subscriptionStatusDropdowns')

export {
    getProceduresTypesEnum,
    getSourcesEnum,
    getPaginationSettings,
    getRowTypeSettings,
    getSubscriptionStatusesEnum,
    getSectorsEnum,
}