export const termsOfUsePath = '/terms-of-use'

const displayName = 'Правила пользования'

export default {
    path: termsOfUsePath,
    name: 'termsOfUse',
    meta: {
        layout: 'privacy-policy-and-term-of-use',
        title: displayName
    },
    component: () => import("@/views/TermsOfUse")
}