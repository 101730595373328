const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST'
const LOGIN_USER_REQUEST_SUCCESS = 'LOGIN_USER_REQUEST_SUCCESS'
const LOGIN_USER_REQUEST_FAILURE  = 'LOGIN_USER_REQUEST_FAILURE'
const LOGOUT_USER = 'LOGOUT_USER'
const REFRESH_TOKENS_REQUEST = 'REFRESH_TOKENS_REQUEST'
const REFRESH_TOKENS_REQUEST_SUCCESS = 'REFRESH_TOKENS_REQUEST_SUCCESS'
const REFRESH_TOKENS_REQUEST_FAILURE = 'REFRESH_TOKENS_REQUEST_FAILURE'
const INIT_REFRESH_TOKENS_REQUEST = 'INIT_REFRESH_TOKENS_REQUEST'

export {
    LOGIN_USER_REQUEST,
    LOGIN_USER_REQUEST_SUCCESS,
    LOGIN_USER_REQUEST_FAILURE,
    LOGOUT_USER,
    REFRESH_TOKENS_REQUEST,
    REFRESH_TOKENS_REQUEST_SUCCESS,
    REFRESH_TOKENS_REQUEST_FAILURE,
    INIT_REFRESH_TOKENS_REQUEST,
}