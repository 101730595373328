import {RESET} from "../../mutation-types"
import {
    CREATE_PROXY_REQUEST,
    CREATE_PROXY_REQUEST_SUCCESS,
    CREATE_PROXY_REQUEST_FAILURE,
    DELETE_PROXY_REQUEST,
    DELETE_PROXY_REQUEST_SUCCESS,
    DELETE_PROXY_REQUEST_FAILURE,
    EDIT_PROXY_REQUEST,
    EDIT_PROXY_REQUEST_SUCCESS,
    EDIT_PROXY_REQUEST_FAILURE,
    GET_PROXIES_REQUEST,
    GET_PROXIES_REQUEST_SUCCESS,
    GET_PROXIES_REQUEST_FAILURE,
} from './mutation-types'
import {map} from "lodash"

const mutations = {
    
    [CREATE_PROXY_REQUEST]: (state) => {
        state.isRequesting = true
    },
    
    [CREATE_PROXY_REQUEST_SUCCESS]: (state) => {
        state.isRequesting = false
    },
    
    [CREATE_PROXY_REQUEST_FAILURE]: (state) => {
        state.isRequesting = false
    },
    
    [DELETE_PROXY_REQUEST]: (state) => {
        state.isRequesting = true
    },
    
    [DELETE_PROXY_REQUEST_SUCCESS]: (state) => {
        state.isRequesting = false
    },
    
    [DELETE_PROXY_REQUEST_FAILURE]: (state) => {
        state.isRequesting = false
    },
    
    [EDIT_PROXY_REQUEST]: (state) => {
        state.isRequesting = true
    },
    
    [EDIT_PROXY_REQUEST_SUCCESS]: (state) => {
        state.isRequesting = false
    },
    
    [EDIT_PROXY_REQUEST_FAILURE]: (state) => {
        state.isRequesting = false
    },
    
    [GET_PROXIES_REQUEST]: (state) => {
        state.isRequesting = true
    },
    
    [GET_PROXIES_REQUEST_SUCCESS]: (state, {_embedded: {parserProxies}}) => {
        state.proxies =
            map(parserProxies, ({id, url, port}) => ({
                id,
                url,
                port,
            }))
        state.isRequesting = false
    },
    
    [GET_PROXIES_REQUEST_FAILURE]: (state) => {
        state.isRequesting = false
    },
    
    [RESET]: (state) => {
        state.proxies = []
    }
}

export default mutations