import axiosInstance from '@/axios'

const editTableSetting = (tableSettingId, tableSetting) =>
    axiosInstance.patch(`/api/profile/tableSettings/${tableSettingId}?projection=tableSettingProjection`, tableSetting)

const updateTableSetting = (tableSettingId, tableSetting) =>
    axiosInstance.patch(`/api/profile/tableSettings/updateSetting/${tableSettingId}`, tableSetting)

const getTableSetting = (profileId) =>
    axiosInstance.get(`/api/profile/profiles/${profileId}/tableSetting?projection=tableSettingProjection`)

export {
    updateTableSetting,
    editTableSetting,
    getTableSetting,
}
