import axiosInstance from '@/axios'
import {createParams, createEqualParam} from "@/utils/searchQueryParamBuilder"
import {map, values, join, filter, isEmpty} from "lodash"

const getUsers = ({page, size, filters}) => {
    const query = join(
        map(
            filter(
                values(filters),
                ({value}) => !isEmpty(value)
            ),
            ({path, operation, value}) => `${path}${operation}${value}`
        )
    )
    return axiosInstance.get(
        `api/profile/admin/users${createParams(
            createEqualParam('page', page),
            createEqualParam('size', size),
            createEqualParam('sort', 'dateAdded,DESC'),
            createEqualParam('search', isEmpty(query) ? null : query)
        )}`
    )
}

const deleteManagerFromUser = (manager, email) => {
    return axiosInstance.delete(`/api/profile/admin/managers/${manager}/users/${email}`)
}

const deleteUser = (email) => {
    return axiosInstance.delete(`/api/profile/admin/users/${email}`)
}

const updateUser = (email, user) => {
    return axiosInstance.put(`/api/profile/admin/users/${email}`, user)
}

const getManagersList = () => {
    return axiosInstance.get(`api/profile/admin/managers${createParams(
        createEqualParam('size', 200),
        createEqualParam('sort', 'dateAdded,DESC'),
    )}`)
}

const getLinkedToManagerUsers = ({page, size, filters, email}) => {
    const query = join(
        map(
            filter(
                values(filters),
                ({value}) => !isEmpty(value)
            ),
            ({path, operation, value}) => `${path}${operation}${value}`
        )
    )
    return axiosInstance.get(
        `api/profile/admin/managers/${email}/${createParams(
            createEqualParam('page', page),
            createEqualParam('size', size),
            createEqualParam('sort', 'dateAdded,DESC'),
            createEqualParam('search', isEmpty(query) ? null : query)
        )}`
    )
}

export {
    getUsers,
    deleteUser,
    updateUser,
    getManagersList,
    getLinkedToManagerUsers,
    deleteManagerFromUser
}