import {
    SET_IMPERSONALIZATION_MODE_ON_REQUEST,
    SET_IMPERSONALIZATION_MODE_ON_REQUEST_SUCCESS,
    SET_IMPERSONALIZATION_MODE_ON_REQUEST_FAILURE,
    SET_IMPERSONALIZATION_MODE_OFF,
} from "./mutation-types"

const actions = {

    turnOnImpersonalizationMode: async ({commit, dispatch, rootGetters}, email) => {
        commit(SET_IMPERSONALIZATION_MODE_ON_REQUEST)
        try {
            await dispatch('profile/getProfile', email, {root: true})
            await dispatch('purchasesTableSettings/getPurchasesTableSetting', rootGetters['profile/profileId'], {root: true})
            await dispatch('filterTabs/getFilterTabs', rootGetters['profile/profileId'], {root: true})

            commit(SET_IMPERSONALIZATION_MODE_ON_REQUEST_SUCCESS, email)
        } catch (e) {
            commit(SET_IMPERSONALIZATION_MODE_ON_REQUEST_FAILURE)
            throw new Error(e.response.data.errorMessage)
        }
    },

    turnOffImpersonalizationMode: async ({commit, dispatch, rootGetters}) => {
        try {
            await dispatch('profile/getProfile', rootGetters['auth/email'], {root: true})
            await dispatch('purchasesTableSettings/getPurchasesTableSetting', rootGetters['profile/profileId'], {root: true})
            await dispatch('filterTabs/getFilterTabs', rootGetters['profile/profileId'], {root: true})

            commit(SET_IMPERSONALIZATION_MODE_OFF)
        } catch (e) {
            throw new Error(e.response.data.errorMessage)
        }
    }
}

export default actions