export const messagePath = '/message'

const displayName = 'Потверждение mail'

export default {
    path: `${messagePath}/:message`,
    meta: {
        layout: 'background-only',
        title: displayName,
    },
    component: () => import("@/views/ConfirmMessageTab")
}