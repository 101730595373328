import {
    SET_COLUMNS_FILTERS,
    ADD_COLUMN_FILTER,
    REMOVE_COLUMN_FILTER,
    REMOVE_COLUMN_FILTERS,
} from "./mutation-types"
import {RESET} from "../../mutation-types"
import {cloneDeep} from "lodash"

const mutations = {

    [SET_COLUMNS_FILTERS]: (state, columnsFilters) => {
        state.columnsFilters = columnsFilters
    },

    [ADD_COLUMN_FILTER]: (state, {id, field, value, filterPath, operation}) => {

        const columnFilters = state.columnsFilters[field]?.columnFilters

        state.columnsFilters = {
            ...state.columnsFilters,
            [field]: {
                columnFilters: {
                    ...columnFilters,
                    [id]: value
                },
                filterPath,
                operation,
            },
        }
    },

    [REMOVE_COLUMN_FILTER]: (state, {id, field, filterPath, operation}) => {

        const columnFilters = cloneDeep(state.columnsFilters[field]?.columnFilters)

        // eslint-disable-next-line no-unused-vars
        const {[id]: removed, ...rest} = columnFilters

        state.columnsFilters = {
            ...state.columnsFilters,
            [field]: {
                columnFilters: rest,
                filterPath,
                operation,
            },
        }
    },

    [REMOVE_COLUMN_FILTERS]: (state, field) => {

        // eslint-disable-next-line no-unused-vars
        const {[field]: removed, ...rest} = state.columnsFilters
        state.columnsFilters = rest
    },

    [RESET]: (state) => {
        state.columnsFilters = {}
    }
}

export default mutations