import axiosInstance from '@/axios'

const getPaymentInvoice = (subscriptionId) => {

    return axiosInstance.get(`/api/profile/pdf/getPaymentInvoice/${subscriptionId}`, {
        responseType: 'blob'
    })
}

const getContract = (subscriptionId) => {

    return axiosInstance.get(`/api/profile/pdf/getContract/${subscriptionId}`, {
        responseType: 'blob'
    })
}

const getCompletedWorkReport = (subscriptionId) => {

    return axiosInstance.get(`/api/profile/pdf/getCompletedWorkReport/${subscriptionId}`, {
        responseType: 'blob'
    })
}

const getServiceAgreement = () => {

    return axiosInstance.get(`/api/profile/pdf/getServiceAgreementReport`, {
        responseType: 'blob'
    })
}

export {
    getPaymentInvoice,
    getContract,
    getCompletedWorkReport,
    getServiceAgreement,
}