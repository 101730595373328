import {
    GET_NOTIFICATIONS_REQUEST,
    GET_NOTIFICATIONS_REQUEST_SUCCESS,
    GET_NOTIFICATIONS_REQUEST_FAILURE,
    GET_NEW_NOTIFICATIONS_REQUEST,
    GET_NEW_NOTIFICATIONS_REQUEST_SUCCESS,
    GET_NEW_NOTIFICATIONS_REQUEST_FAILURE,
    GET_DISPLAYED_NOTIFICATIONS_REQUEST,
    GET_DISPLAYED_NOTIFICATIONS_REQUEST_SUCCESS,
    GET_DISPLAYED_NOTIFICATIONS_REQUEST_FAILURE,
    GET_HISTORY_NOTIFICATIONS_REQUEST,
    GET_HISTORY_NOTIFICATIONS_REQUEST_SUCCESS,
    GET_HISTORY_NOTIFICATIONS_REQUEST_FAILURE,
} from "./mutation-types"
import api from "@/application"
import { reload } from "../../../utils/constants/errorMessages"

const actions = {

    getAllNotifications: async ({commit}, profileId) => {
        
        commit(GET_NOTIFICATIONS_REQUEST)
        try {
            const {data} = await api.notifications.getNotifications(profileId)
            commit(GET_NOTIFICATIONS_REQUEST_SUCCESS, data)
        } catch (e) {
            commit(GET_NOTIFICATIONS_REQUEST_FAILURE)
            throw new Error(reload)
        }
    },
    getNewNotifications: async ({commit}, profileId) => {
        commit(GET_NEW_NOTIFICATIONS_REQUEST)
        try {
            const {data} = await api.notifications.getNotificationsNew(profileId)
            commit(GET_NEW_NOTIFICATIONS_REQUEST_SUCCESS, data)
        } catch (e) {
            commit(GET_NEW_NOTIFICATIONS_REQUEST_FAILURE)
            throw new Error(reload)
        }
    },
    getDisplayedNotifications: async ({commit}, profileId) => {
        commit(GET_DISPLAYED_NOTIFICATIONS_REQUEST)
        try {
            const {data} = await api.notifications.getNotificationsDisplayed(profileId)

            commit(GET_DISPLAYED_NOTIFICATIONS_REQUEST_SUCCESS, data)
            return data
        } catch (e) {
            commit(GET_DISPLAYED_NOTIFICATIONS_REQUEST_FAILURE)
        }
    },
    getHistoryNotifications: async ({commit}) => {
        commit(GET_HISTORY_NOTIFICATIONS_REQUEST)
        try {
            const {data} = await api.notifications.getNotificationsHistory()
            commit(GET_HISTORY_NOTIFICATIONS_REQUEST_SUCCESS, data)
        } catch (e) {
            commit(GET_HISTORY_NOTIFICATIONS_REQUEST_FAILURE)
        }
    },

}

export default actions
