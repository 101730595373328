import home from "./home"
import purchases from "./purchases"
import profile from "./profile"
import confirmMessage from "./confirmMessage"
import notFound, {notFoundPath} from "./notFound"
import users from "./users"
import parsers from "./parsers"
import privacyPolicy from "./privacyPolicy"
import termsOfUse from "./termsOfUse"
import notifications from "./notifications"
import notificationsAdmin from "./notificationsAdmin"

export const routes = [
    home,
    purchases,
    profile,
    confirmMessage,
    notFound,
    users,
    parsers,
    privacyPolicy,
    termsOfUse,
    notifications,
    notificationsAdmin,
    {
        path: '*',
        redirect: notFoundPath,
    }
]