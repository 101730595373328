import {and, comparison, ge, inList, le, Operation} from "rsql-builder"
import {filter, isArray, isEmpty, map} from "lodash"
import escapeValue, {EscapedValue} from "rsql-builder/dist/escape-value"

const inReg = (...value) => new Operation(new EscapedValue(`(${value.map(escapeValue)})`), "=inreg=")
const outReg = (...value) => new Operation(new EscapedValue(`(${value.map(escapeValue)})`), "=outreg=")
const okrb = (...value) => new Operation(new EscapedValue(`(${value.map(escapeValue)})`), "=okrb=")
export const equaldate = (...value) => new Operation(new EscapedValue(`(${value.map(escapeValue)})`), "=equaldate=")

export const generateFilterQuery = (customFilters, tabFilters) => {

    return and(
        ...generateTabFilters(tabFilters),
        ...map(
            filter(
                customFilters,
                'value',
            ),
            (field) =>
                isArray(field.value)
                    ? mapList(field.filterPath, field.value, field.operation)
                    : comparison(field.filterPath, field.operation(field.value))
        )
    )
}

const mapList = (path, list, operation = inList, resultOperation = comparison) => isEmpty(list)
    ? resultOperation(path, operation(''))
    : resultOperation(path, operation(...list))

export const generateTabFilters = (tabFilters) => {
    const result = []

    !isEmpty(tabFilters.keyWords) && result.push(mapList('nameVector', tabFilters.keyWords, inReg),)
    !isEmpty(tabFilters.excludeWords) && result.push(mapList('nameVector', tabFilters.excludeWords, outReg))
    tabFilters.budgetFrom && result.push(comparison('price', ge(tabFilters.budgetFrom)))
    tabFilters.budgetTo && result.push(comparison('price', le(tabFilters.budgetTo)))
    !isEmpty(tabFilters.codesOKRB) && result.push(mapList('codeOKRB', tabFilters.codesOKRB, okrb))
    !isEmpty(tabFilters.organizations) && result.push(mapList('proposal.organization', tabFilters.organizations, inReg))
    !isEmpty(tabFilters.regions) && result.push(mapList('proposal.organization', tabFilters.regions, inReg))
    !isEmpty(tabFilters.placesTypes) && result.push(mapList('proposal.source.id', map(tabFilters.placesTypes, 'id')))
    !isEmpty(tabFilters.purchasesTypes) && result.push(mapList('proposal.procedureType.id', map(tabFilters.purchasesTypes, 'id')))
    !isEmpty(tabFilters.sectorsTypes) && result.push(mapList('proposal.sector.id', map(tabFilters.sectorsTypes,'id')))

    return result
}