import axiosInstance from '@/axios'

const createSubscription = (subscriptionData) => {

    return axiosInstance.post(`/api/profile/subscriptions`, subscriptionData)
}

const deleteSubscription = (subscriptionId) => {

    return axiosInstance.delete(`/api/profile/subscriptions/${subscriptionId}`)
}

const editSubscription = (subscriptionId, subscriptionData) => {

    return axiosInstance.patch(`/api/profile/subscriptions/${subscriptionId}`, subscriptionData)
}

const getProfileSubscriptions = (profileId) => {

    return axiosInstance.get(`/api/profile/subscriptions/search/findAllByProfileId?profileId=${profileId}`)
}

const validateSubscription = (validateSubscriptionDto) => {

    return axiosInstance.post(`/api/profile/subscriptions/validate`, validateSubscriptionDto)
}

export {
    createSubscription,
    deleteSubscription,
    editSubscription,
    getProfileSubscriptions,
    validateSubscription,
}
