const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST'
const GET_NOTIFICATIONS_REQUEST_SUCCESS = 'GET_NOTIFICATIONS_REQUEST_SUCCESS'
const GET_NOTIFICATIONS_REQUEST_FAILURE = 'GET_NOTIFICATIONS_REQUEST_FAILURE'
const GET_NEW_NOTIFICATIONS_REQUEST = 'GET_NEW_NOTIFICATIONS_REQUEST'
const GET_NEW_NOTIFICATIONS_REQUEST_SUCCESS = 'GET_NEW_NOTIFICATIONS_REQUEST_SUCCESS'
const GET_NEW_NOTIFICATIONS_REQUEST_FAILURE = 'GET_NEW_NOTIFICATIONS_REQUEST_FAILURE'
const GET_DISPLAYED_NOTIFICATIONS_REQUEST = 'GET_DISPLAYED_NOTIFICATIONS_REQUEST'
const GET_DISPLAYED_NOTIFICATIONS_REQUEST_SUCCESS = 'GET_DISPLAYED_NOTIFICATIONS_REQUEST_SUCCESS'
const GET_DISPLAYED_NOTIFICATIONS_REQUEST_FAILURE = 'GET_DISPLAYED_NOTIFICATIONS_REQUEST_FAILURE'
const GET_HISTORY_NOTIFICATIONS_REQUEST = 'GET_HISTORY_NOTIFICATIONS_REQUEST'
const GET_HISTORY_NOTIFICATIONS_REQUEST_SUCCESS = 'GET_HISTORY_NOTIFICATIONS_REQUEST_SUCCESS'
const GET_HISTORY_NOTIFICATIONS_REQUEST_FAILURE = 'GET_HISTORY_NOTIFICATIONS_REQUEST_FAILURE'

export {
    GET_NOTIFICATIONS_REQUEST,
    GET_NOTIFICATIONS_REQUEST_SUCCESS,
    GET_NOTIFICATIONS_REQUEST_FAILURE,
    GET_NEW_NOTIFICATIONS_REQUEST,
    GET_NEW_NOTIFICATIONS_REQUEST_SUCCESS,
    GET_NEW_NOTIFICATIONS_REQUEST_FAILURE,
    GET_DISPLAYED_NOTIFICATIONS_REQUEST,
    GET_DISPLAYED_NOTIFICATIONS_REQUEST_SUCCESS,
    GET_DISPLAYED_NOTIFICATIONS_REQUEST_FAILURE,
    GET_HISTORY_NOTIFICATIONS_REQUEST,
    GET_HISTORY_NOTIFICATIONS_REQUEST_SUCCESS,
    GET_HISTORY_NOTIFICATIONS_REQUEST_FAILURE,
}