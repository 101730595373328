import children from "./children"

export const profilePath = '/profile'

export default {
    path: profilePath,
    icon: 'icoPerson',
    display: 'Мой профиль',
    meta: {
        layout: 'main',
        requiresAuth: true
    },
    children: children,
    component: () => import("@/views/ProfileTab/Profile")
}