const SET_ORGANIZATION = 'SET_ORGANIZATION'
const ORGANIZATION_REQUEST = 'ORGANIZATION_REQUEST'
const ORGANIZATION_REQUEST_FAILURE = 'ORGANIZATION_REQUEST_FAILURE'
const EDIT_ORGANIZATION_REQUEST = 'EDIT_ORGANIZATION_REQUEST'
const EDIT_ORGANIZATION_REQUEST_FAILURE = 'EDIT_ORGANIZATION_REQUEST_FAILURE'
const EDIT_ORGANIZATION_REQUEST_SUCCESS = 'EDIT_ORGANIZATION_REQUEST_SUCCESS'
const SAVE_ORGANIZATION_REQUEST = 'SAVE_ORGANIZATION_REQUEST'
const SAVE_ORGANIZATION_REQUEST_FAILURE = 'SAVE_ORGANIZATION_REQUEST_FAILURE'
const SAVE_ORGANIZATION_REQUEST_SUCCESS = 'SAVE_ORGANIZATION_REQUEST_SUCCESS'

export {
    SET_ORGANIZATION,
    ORGANIZATION_REQUEST,
    ORGANIZATION_REQUEST_FAILURE,
    EDIT_ORGANIZATION_REQUEST,
    EDIT_ORGANIZATION_REQUEST_FAILURE,
    EDIT_ORGANIZATION_REQUEST_SUCCESS,
    SAVE_ORGANIZATION_REQUEST,
    SAVE_ORGANIZATION_REQUEST_FAILURE,
    SAVE_ORGANIZATION_REQUEST_SUCCESS
}
