import axiosInstance from '@/axios'

export const editOrganization = (organizationId, formData) => {
    
    return axiosInstance.put(`/api/profile/organizations/${organizationId}?projection=organizationProjection`, formData)
}

export const getOrganization = (profileId) => {
    
    return axiosInstance.get(`/api/profile/profiles/${profileId}/organization?projection=organizationProjection`)
}

export const saveOrganization = (formData) => {

    return axiosInstance.post(`/api/profile/organizations?projection=organizationProjection`, formData)
}

export const isExistsOrganizationByProfileId = (profileId) => {

    return axiosInstance.get(`/api/profile/organizations/search/existsByProfileId?profileId=${profileId}`)
}
