const CREATE_SUBSCRIPTION_REQUEST = 'CREATE_SUBSCRIPTION_REQUEST'
const CREATE_SUBSCRIPTION_REQUEST_SUCCESS = 'CREATE_SUBSCRIPTION_REQUEST_SUCCESS'
const CREATE_SUBSCRIPTION_REQUEST_FAILURE = 'CREATE_SUBSCRIPTION_REQUEST_FAILURE'
const EDIT_SUBSCRIPTION_REQUEST = 'EDIT_SUBSCRIPTION_REQUEST'
const EDIT_SUBSCRIPTION_REQUEST_SUCCESS = 'EDIT_SUBSCRIPTION_REQUEST_SUCCESS'
const EDIT_SUBSCRIPTION_REQUEST_FAILURE = 'EDIT_SUBSCRIPTION_REQUEST_FAILURE'
const DELETE_SUBSCRIPTION_REQUEST = 'DELETE_SUBSCRIPTION_REQUEST'
const DELETE_SUBSCRIPTION_REQUEST_SUCCESS = 'DELETE_SUBSCRIPTION_REQUEST_SUCCESS'
const DELETE_SUBSCRIPTION_REQUEST_FAILURE = 'DELETE_SUBSCRIPTION_REQUEST_FAILURE'
const GET_SUBSCRIPTIONS_REQUEST = 'GET_SUBSCRIPTIONS_REQUEST'
const GET_SUBSCRIPTIONS_REQUEST_SUCCESS = 'GET_SUBSCRIPTIONS_REQUEST_SUCCESS'
const GET_SUBSCRIPTIONS_REQUEST_FAILURE = 'GET_SUBSCRIPTIONS_REQUEST_FAILURE'

export {
    CREATE_SUBSCRIPTION_REQUEST,
    CREATE_SUBSCRIPTION_REQUEST_SUCCESS,
    CREATE_SUBSCRIPTION_REQUEST_FAILURE,
    DELETE_SUBSCRIPTION_REQUEST,
    DELETE_SUBSCRIPTION_REQUEST_SUCCESS,
    DELETE_SUBSCRIPTION_REQUEST_FAILURE,
    EDIT_SUBSCRIPTION_REQUEST,
    EDIT_SUBSCRIPTION_REQUEST_SUCCESS,
    EDIT_SUBSCRIPTION_REQUEST_FAILURE,
    GET_SUBSCRIPTIONS_REQUEST,
    GET_SUBSCRIPTIONS_REQUEST_SUCCESS,
    GET_SUBSCRIPTIONS_REQUEST_FAILURE,
}