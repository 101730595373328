import {filter, isEmpty, map, reduce, toPairs, values} from "lodash"
import {uuid} from "vue-uuid"
import addDays from "date-fns/addDays"
import format from "date-fns/format"

const mapSearchObjToEdit = (field) =>

    reduce(
        field,
        (obj, param) => {
            obj[uuid.v4()] = {
                text: param
            }

            return obj
        },
        {}
    )

const mapFromDbToForm = (filterTab) => ({

    id: filterTab.id,
    name: filterTab.name,
    budgetFrom: filterTab.searchCriteria.budgetFrom,
    budgetTo: filterTab.searchCriteria.budgetTo,
    keyWords: mapSearchObjToEdit(filterTab.searchCriteria.keyWords),
    excludeWords: mapSearchObjToEdit(filterTab.searchCriteria.excludeWords),
    regions: mapSearchObjToEdit(filterTab.searchCriteria.regions),
    organizations: mapSearchObjToEdit(filterTab.searchCriteria.organizations),
    codesOKRB: mapSearchObjToEdit(filterTab.searchCriteria.codesOKRB),
    placesTypes: filterTab.searchCriteria.placesTypes,
    purchasesTypes: filterTab.searchCriteria.purchasesTypes,
    sectorsTypes: filterTab.searchCriteria.sectorsTypes,
})

const mapColumnsFilters = (columnsFilters) =>

    map(filter(toPairs(columnsFilters), (it) => !isEmpty(it[1]?.columnFilters)), ([key, {filterPath, columnFilters, operation}]) => {

        const filterValues = map(values(columnFilters), 'text')
        let fValues = filterValues

        if (key === 'expirationDays') {
            const now = new Date()
            fValues = map(filterValues, it => format(addDays(now, parseInt(it)), "dd.MM.yyyy"))
        }

        return {
            operation,
            value: fValues,
            filterPath
        }
    })

const mapColumnSetting = (columnSetting) => ({

    id: columnSetting.id,
    field: columnSetting.field,
    index: columnSetting.index,
    width: columnSetting.width,
    hide: columnSetting.hide,
})

const createFilterData = (formData) => ({

    name: formData.name,
    searchCriteria: {
        purchasesTypes: formData.purchasesTypes,
        sectorsTypes: formData.sectorsTypes,
        placesTypes: formData.placesTypes,
        keyWords: map(values(formData.keyWords), 'text'),
        excludeWords: map(values(formData.excludeWords), 'text'),
        regions: map(values(formData.regions), 'text'),
        organizations: map(values(formData.organizations), 'text'),
        codesOKRB: map(values(formData.codesOKRB), 'text'),
        budgetFrom: !formData.budgetFrom ? null : Number(formData.budgetFrom),
        budgetTo: !formData.budgetTo ? null : Number(formData.budgetTo),
    },
})

export {
    mapColumnsFilters,
    mapColumnSetting,
    mapFromDbToForm,
    createFilterData,
}
