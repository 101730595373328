export const clickOutside = {
    bind (el, binding, vnode) {
        const vm = vnode.context;
        const callback = binding.value
        
        el.clickOutsideEvent = function (event) {
            if (vm.$options.name === 'PurchasesTableSettings' && vm.currentVueTourStep('startTour') > 6 && vm.currentVueTourStep('startTour') < 12) {
                return;
            }
            if (!(el === event.target || el.contains(event.target))) {
                return callback.call(vm, event);
            }
        }
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unbind(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    }
}
