export const purchasesPath = '/purchases-monitoring'

const displayName = 'Мониторинг закупок'

export default {
    path: `${purchasesPath}/:id?`,
    name: 'purchases-monitoring',
    icon: 'icoSearch',
    display: displayName,
    meta: {
        layout: 'main',
        requiresAuth: true,
        title: displayName,
    },
    component: () => import("@/views/PurchasesMonitoring")
}