<template>
    <div class="server-error">
        <ico name="icoErrorOutline" color="#F84242"></ico>
        <p>{{errorMessage}}</p>
    </div>
</template>

<script>
    export default {
        name: "ErrorMessage",
        props: ['errorMessage']
    }
</script>

<style lang="less">
    @import '../../assets/less/variables.less';

    .server-error {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 0;
        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 0;
        }
        svg {
            display: block;
            flex-shrink: 0;
        }
        p {
            padding-left: 8px;
            color: @error-color;
        }
    }
</style>
