const USERS_REQUEST = 'USERS_REQUEST'
const USERS_REQUEST_FAILURE = 'USERS_REQUEST_FAILURE'
const USERS_REQUEST_SUCCESS = 'USERS_REQUEST_SUCCESS'
const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST'
const DELETE_USER_REQUEST_SUCCESS = 'DELETE_USER_REQUEST_SUCCESS'
const DELETE_USER_REQUEST_FAILURE = 'DELETE_USER_REQUEST_FAILURE'
const CHANGE_USER_STATUS_REQUEST = 'CHANGE_USER_STATUS_REQUEST'
const CHANGE_USER_STATUS_REQUEST_SUCCESS = 'CHANGE_USER_STATUS_REQUEST_SUCCESS'
const CHANGE_USER_STATUS_REQUEST_FAILURE = 'CHANGE_USER_STATUS_REQUEST_FAILURE'
const ADD_USER_ROLE_REQUEST = 'ADD_USER_ROLE_REQUEST'
const ADD_USER_ROLE_REQUEST_SUCCESS = 'ADD_USER_ROLE_REQUEST_SUCCESS'
const ADD_USER_ROLE_REQUEST_FAILURE = 'ADD_USER_ROLE_REQUEST_FAILURE'
const DELETE_USER_ROLE_REQUEST = 'DELETE_USER_ROLE_REQUEST'
const DELETE_USER_ROLE_REQUEST_SUCCESS = 'DELETE_USER_ROLE_REQUEST_SUCCESS'
const DELETE_USER_ROLE_REQUEST_FAILURE = 'DELETE_USER_ROLE_REQUEST_FAILURE'

const SORT_USER_BY_ONLINE = 'SORT_USER_BY_ONLINE'

const SET_MANAGER_TO_USER = 'SET_MANAGER_TO_USER'
const SET_MANAGER_TO_USER_SUCCESS = 'SET_MANAGER_TO_USER_SUCCESS'
const SET_MANAGER_TO_USER_FAILURE = 'SET_MANAGER_TO_USER_FAILURE'

const MANAGER_USERS_REQUEST = 'MANAGER_USERS_REQUEST'

const MANAGERS_LIST_REQUEST = 'MANAGERS_LIST_REQUEST'
const MANAGERS_LIST_REQUEST_SUCCESS = 'MANAGERS_LIST_REQUEST_SUCCESS'
const MANAGERS_LIST_REQUEST_FAILURE = 'MANAGERS_LIST_REQUEST_FAILURE'

const DELETE_MANAGER_FROM_USER_SUCCESS = 'DELETE_MANAGER_FROM_USER_SUCCESS'
const DELETE_MANAGER_FROM_USER_FAILURE = 'DELETE_MANAGER_FROM_USER_FAILURE'
const DELETE_MANAGER_FROM_USER = 'DELETE_MANAGER_FROM_USER'

export {
    USERS_REQUEST,
    USERS_REQUEST_FAILURE,
    USERS_REQUEST_SUCCESS,
    DELETE_USER_REQUEST,
    DELETE_USER_REQUEST_FAILURE,
    DELETE_USER_REQUEST_SUCCESS,
    CHANGE_USER_STATUS_REQUEST,
    CHANGE_USER_STATUS_REQUEST_SUCCESS,
    CHANGE_USER_STATUS_REQUEST_FAILURE,
    ADD_USER_ROLE_REQUEST,
    ADD_USER_ROLE_REQUEST_SUCCESS,
    ADD_USER_ROLE_REQUEST_FAILURE,
    DELETE_USER_ROLE_REQUEST,
    DELETE_USER_ROLE_REQUEST_SUCCESS,
    DELETE_USER_ROLE_REQUEST_FAILURE,
    SORT_USER_BY_ONLINE,
    MANAGERS_LIST_REQUEST,
    MANAGERS_LIST_REQUEST_SUCCESS,
    MANAGERS_LIST_REQUEST_FAILURE,
    MANAGER_USERS_REQUEST,
    SET_MANAGER_TO_USER,
    SET_MANAGER_TO_USER_SUCCESS,
    SET_MANAGER_TO_USER_FAILURE,
    DELETE_MANAGER_FROM_USER_SUCCESS,
    DELETE_MANAGER_FROM_USER_FAILURE,
    DELETE_MANAGER_FROM_USER
}