export const newNotificationsPath = ''

const displayName = 'Новые уведомления'

export default {
    name: 'newNotifications',
    display: 'Новые уведомления',
    path: newNotificationsPath,
    meta: {
        layout: 'main',
        requiresAuth: true,
        title: displayName,
    },
    component: () => import("@/views/Notifications/components/NewNotifications")
}