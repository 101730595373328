<template>
    <div class="modal-content">
        <form novalidate @submit.prevent="save">
            <ErrorMessage v-if="errorMessage" :error-message="errorMessage"></ErrorMessage>
            <label class="form-control-label">Выберите дату начала подписки</label>
            <div class="datepicker-container">
                        <input
                                :value="date.toLocaleDateString('ru', {day: '2-digit', month: '2-digit', year: '2-digit'})"
                                type="text"
                                class="form-control form-control-light"
                                readonly
                        >
                    </div>
            <v-date-picker
                    class="datepicker"
                    :locale="'ru-Ru'"
                    :masks="{input: 'DD.MM.YYYY'}"
                    v-model="date"
                    :step="1"
                    :color="'gray'"
                    :min-date="minDate"
                    :disabled-dates="disabledDates"
                    :columns="2"
                    
            >
            </v-date-picker>
            <div class="form-footer">
                <button class="btn btn-middle" type="submit" :disabled="!date">Далее</button>
            </div>
        </form>
    </div>
</template>
<script>
    import SubscriptionModal from '@/components/profilePage/SubscriptionModal'
    import {SubscriptionModalProperties} from "@/utils/modalsProperties"
    import ErrorMessage from '@/components/common/ErrorMessage'
    import {mapActions, mapGetters} from "vuex"
    import api from "@/application"
    import format from "date-fns/format"
    import {filter, map} from "lodash"

    export default {
        name: "SubscriptionDateModal",
        data() {
            return {
                date: new Date(),
                minDate: new Date(),
                errorMessage: null,
            }
        },
        components: {
            ErrorMessage
        },
        computed: {
            ...mapGetters('profile', ['profileId']),
            ...mapGetters('subscriptions', ['subscriptions']),
            disabledDates() {

                return map(filter(this.subscriptions, subscription => subscription.status.id === 2 || subscription.status.id === 3), ({startDate, endDate}) => ({start: startDate, end: endDate}))
            },
        },
        methods: {
            ...mapActions('subscriptions', ['createSubscription', 'getSubscriptions']),
            openSubscriptionModal() {
                this.$modal.show(
                    SubscriptionModal,
                    {
                        createSubscription: this.saveSubscription,
                    },
                    SubscriptionModalProperties
                )
            },
            async save() {
                try {
                    const {data} = await api.subscriptions.validateSubscription({profileId: this.profileId, startDate: format(this.date, 'yyyy-MM-dd')})
                    if (data) {
                        this.openSubscriptionModal()
                        return
                    }

                    this.$toast.error('На данную дату уже есть активная подписка')

                } catch (e) {
                    this.$toast.error(e.response.data.errorMessage)
                }

            },
            async saveSubscription() {
                await this.createSubscription({profile: `/${this.profileId}`, startDate: format(this.date, 'yyyy-MM-dd')})
                await this.getSubscriptions(this.profileId)
            },
        },
    }
</script>
<style lang="less" scoped>
    @import '../../assets/less/variables.less';

    
    label{
        padding-left: 20px;
    }
    .datepicker-container {
        display: flex;
        justify-content: space-between;

        

        input {
            flex-grow: 1;
            flex-shrink: 1;
            cursor: pointer;
            margin: 0 20px 30px 20px;
        }

        button {
            flex-shrink: 0;
            flex-grow: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
        }
    }

    .form-footer {
        padding: 32px 20px 0 0;
    }
</style>