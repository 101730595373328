const SET_PROFILE = 'SET_PROFILE'
const PROFILE_REQUEST = 'PROFILE_REQUEST'
const PROFILE_REQUEST_FAILURE = 'PROFILE_REQUEST_FAILURE'
const UPDATE_PROFILE_PHONE_REQUEST = 'UPDATE_PROFILE_PHONE_REQUEST'
const UPDATE_PROFILE_PHONE_REQUEST_FAILURE = 'UPDATE_PROFILE_PHONE_REQUEST_FAILURE'
const UPDATE_PROFILE_PHONE_REQUEST_SUCCESS = 'UPDATE_PROFILE_PHONE_REQUEST_SUCCESS'
const UPDATE_PROFILE_HINTS_ENABLED_REQUEST = 'UPDATE_PROFILE_HINTS_ENABLED_REQUEST'
const UPDATE_PROFILE_HINTS_ENABLED_REQUEST_FAILURE = 'UPDATE_PROFILE_HINTS_ENABLED_REQUEST_FAILURE'
const UPDATE_PROFILE_HINTS_ENABLED_REQUEST_SUCCESS = 'UPDATE_PROFILE_HINTS_ENABLED_REQUEST_SUCCESS'

export {
    SET_PROFILE,
    PROFILE_REQUEST,
    PROFILE_REQUEST_FAILURE,
    UPDATE_PROFILE_PHONE_REQUEST,
    UPDATE_PROFILE_PHONE_REQUEST_FAILURE,
    UPDATE_PROFILE_PHONE_REQUEST_SUCCESS,
    UPDATE_PROFILE_HINTS_ENABLED_REQUEST,
    UPDATE_PROFILE_HINTS_ENABLED_REQUEST_FAILURE,
    UPDATE_PROFILE_HINTS_ENABLED_REQUEST_SUCCESS,
}