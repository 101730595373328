import {
    SET_SCROLL
} from './mutations-types'
import {RESET} from "../../mutation-types"

const mutations = {

    [SET_SCROLL]: (state, scroll) => {
        state.scroll = scroll
    },

    [RESET]: (state) => {
        state.scroll = 0
    }
}

export default mutations
