import api from "@/application"
import {
    SET_ORGANIZATION,
    ORGANIZATION_REQUEST,
    ORGANIZATION_REQUEST_FAILURE,
    EDIT_ORGANIZATION_REQUEST,
    EDIT_ORGANIZATION_REQUEST_FAILURE,
    EDIT_ORGANIZATION_REQUEST_SUCCESS,
    SAVE_ORGANIZATION_REQUEST,
    SAVE_ORGANIZATION_REQUEST_FAILURE,
    SAVE_ORGANIZATION_REQUEST_SUCCESS
} from "./mutation-types"

const actions = {
    getOrganization: async ({commit}, profileId) => {
        commit(ORGANIZATION_REQUEST)
        try {
            const {data} = await api.organization.getOrganization(profileId)
            commit(SET_ORGANIZATION, data)
        } catch (e) {
            commit(ORGANIZATION_REQUEST_FAILURE)
            throw new Error(e.response.data.errorMessage)
        }
    },
    editOrganization: async ({commit}, {organizationId, formData}) => {
        commit(EDIT_ORGANIZATION_REQUEST)
        try {
            const {data} = await api.organization.editOrganization(organizationId, formData)
            commit(EDIT_ORGANIZATION_REQUEST_SUCCESS, data)
        } catch (e) {
            commit(EDIT_ORGANIZATION_REQUEST_FAILURE)
            throw new Error(e.response.data.errorMessage)
        }
    },
    saveOrganization: async ({commit}, formData) => {
        commit(SAVE_ORGANIZATION_REQUEST)
        try {
            const {data} = await api.organization.saveOrganization(formData)
            commit(SAVE_ORGANIZATION_REQUEST_SUCCESS, data)
        } catch (e) {
            commit(SAVE_ORGANIZATION_REQUEST_FAILURE)
            throw new Error(e.response.data.errorMessage)
        }
    }
}

export default actions
