const getters = {
    purchasesTableColumns: (state) => state.purchasesTableColumns,
    getColumnProperty: (state) => (columnName, columnProperty) => state.purchasesTableColumns[columnName][columnProperty],
    lineHeightsType: (state) => state.lineHeightsType,
    pagination: (state) => state.pagination,
    tableSettingId: (state) => state.tableSettingId,
    isActiveSearch: (state) => state.isActiveSearch,
    isKeywordShown: (state) => state.isKeywordShown,
}

export default getters
