const GET_PURCHASES_REQUEST = 'GET_PURCHASES_REQUEST'
const GET_PURCHASES_REQUEST_SUCCESS = 'GET_PURCHASES_REQUEST_SUCCESS'
const GET_PURCHASES_REQUEST_FAILURE = 'GET_PURCHASES_REQUEST_FAILURE'
const SET_FAVORITE_PURCHASE = 'SET_FAVORITE_PURCHASE'

export {
    GET_PURCHASES_REQUEST,
    GET_PURCHASES_REQUEST_SUCCESS,
    GET_PURCHASES_REQUEST_FAILURE,
    SET_FAVORITE_PURCHASE,
}