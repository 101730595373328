import {
    GET_NOTIFICATIONS_REQUEST,
    GET_NOTIFICATIONS_REQUEST_SUCCESS,
    GET_NOTIFICATIONS_REQUEST_FAILURE,
    GET_NEW_NOTIFICATIONS_REQUEST,
    GET_NEW_NOTIFICATIONS_REQUEST_SUCCESS,
    GET_NEW_NOTIFICATIONS_REQUEST_FAILURE,
    GET_DISPLAYED_NOTIFICATIONS_REQUEST,
    GET_DISPLAYED_NOTIFICATIONS_REQUEST_SUCCESS,
    GET_DISPLAYED_NOTIFICATIONS_REQUEST_FAILURE,
    GET_HISTORY_NOTIFICATIONS_REQUEST,
    GET_HISTORY_NOTIFICATIONS_REQUEST_SUCCESS,
    GET_HISTORY_NOTIFICATIONS_REQUEST_FAILURE,
} from "./mutation-types"
import {RESET} from "../../mutation-types"

const mutations = {

    [GET_NOTIFICATIONS_REQUEST]: (state) => {
        state.isRequesting = true
    },

    [GET_NOTIFICATIONS_REQUEST_SUCCESS]: (state, notificationsResponses) => {
        state.allNotifications = notificationsResponses
        state.isRequesting = false
    },

    [GET_NOTIFICATIONS_REQUEST_FAILURE]: (state) => {
        state.isRequesting = false
    },

    [GET_NEW_NOTIFICATIONS_REQUEST]: (state) => {
        state.isRequesting = true
    },

    [GET_NEW_NOTIFICATIONS_REQUEST_SUCCESS]: (state, newNotificationsResponses) => {
        state.newNotifications = newNotificationsResponses
        state.isRequesting = false
    },

    [GET_NEW_NOTIFICATIONS_REQUEST_FAILURE]: (state) => {
        state.isRequesting = false
    },

    [GET_DISPLAYED_NOTIFICATIONS_REQUEST]: (state) => {
        state.isRequesting = true
    },

    [GET_DISPLAYED_NOTIFICATIONS_REQUEST_SUCCESS]: (state, displayedNotificationsResponses) => {
        state.displayedNotifications = displayedNotificationsResponses
        state.isRequesting = false
    },

    [GET_DISPLAYED_NOTIFICATIONS_REQUEST_FAILURE]: (state) => {
        state.isRequesting = false
    },

    [GET_HISTORY_NOTIFICATIONS_REQUEST]: (state) => {
        state.isRequesting = true
    },

    [GET_HISTORY_NOTIFICATIONS_REQUEST_SUCCESS]: (state, historyNotificationsResponses) => {
        state.historyNotifications = historyNotificationsResponses
        state.isRequesting = false
    },

    [GET_HISTORY_NOTIFICATIONS_REQUEST_FAILURE]: (state) => {
        state.isRequesting = false
    },

    [RESET]: (state) => {
        state.notifications = []
        state.newNotifications = []
    }
}
export default mutations
