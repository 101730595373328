import api from "@/application"
import {
    PROFILE_REQUEST,
    PROFILE_REQUEST_FAILURE,
    SET_PROFILE,
    UPDATE_PROFILE_PHONE_REQUEST,
    UPDATE_PROFILE_PHONE_REQUEST_FAILURE,
    UPDATE_PROFILE_PHONE_REQUEST_SUCCESS,
    UPDATE_PROFILE_HINTS_ENABLED_REQUEST,
    UPDATE_PROFILE_HINTS_ENABLED_REQUEST_FAILURE,
    UPDATE_PROFILE_HINTS_ENABLED_REQUEST_SUCCESS,
} from "./mutation-types"
import {SET_ORGANIZATION} from "../organization/mutation-types"

const actions = {

    getProfile: async ({commit}, email) => {
        commit(PROFILE_REQUEST)
        try {
            const {data} = await api.profile.getProfileByEmail(email)
            // commit to profile state
            commit(SET_PROFILE, {id: data.id, phone: data.phone, subscriptionEndDate: data.subscriptionEndDate, hintsEnabled: data.hintsEnabled})
            // commit to organization state
            commit(`organization/${SET_ORGANIZATION}`, data.organization, {root: true})
        } catch (e) {
            commit(PROFILE_REQUEST_FAILURE)
            throw new Error(e)
        }
    },

    updatePhone: async ({commit}, {id, phone}) => {
        commit(UPDATE_PROFILE_PHONE_REQUEST)
        try {
            await api.profile.updatePhone(id, phone)
            commit(UPDATE_PROFILE_PHONE_REQUEST_SUCCESS, phone)
        } catch (e) {
            commit(UPDATE_PROFILE_PHONE_REQUEST_FAILURE)
            throw new Error(e.response.data.errorMessage)
        }
    },

    updateHintsEnabled: async ({commit}, {id, hintsEnabled}) => {
        commit(UPDATE_PROFILE_HINTS_ENABLED_REQUEST)
        try {
            await api.profile.updateHintsEnabled(id, hintsEnabled)
            commit(UPDATE_PROFILE_HINTS_ENABLED_REQUEST_SUCCESS, hintsEnabled)
        } catch (e) {
            commit(UPDATE_PROFILE_HINTS_ENABLED_REQUEST_FAILURE)
            throw new Error(e.response.data.errorMessage)
        }
    },
}

export default actions
