import {
    CHANGE_COLUMN_SORT,
    REMOVE_COLUMN_SORT,
} from "./mutation-types"
import {RESET} from "../../mutation-types"

const actions = {

    setColumnSort: ({commit}, payload) => {
        commit(CHANGE_COLUMN_SORT, payload)
    },

    removeColumnSort: ({commit}, payload) => {
        commit(REMOVE_COLUMN_SORT, payload)
    },

    resetColumnSort: ({commit}) => {
        commit(RESET)
    }
}

export default actions