import {
    CREATE_SUBSCRIPTION_REQUEST,
    CREATE_SUBSCRIPTION_REQUEST_FAILURE,
    CREATE_SUBSCRIPTION_REQUEST_SUCCESS,
    DELETE_SUBSCRIPTION_REQUEST,
    DELETE_SUBSCRIPTION_REQUEST_FAILURE,
    DELETE_SUBSCRIPTION_REQUEST_SUCCESS,
    EDIT_SUBSCRIPTION_REQUEST,
    EDIT_SUBSCRIPTION_REQUEST_FAILURE,
    EDIT_SUBSCRIPTION_REQUEST_SUCCESS,
    GET_SUBSCRIPTIONS_REQUEST,
    GET_SUBSCRIPTIONS_REQUEST_FAILURE,
    GET_SUBSCRIPTIONS_REQUEST_SUCCESS,
} from './mutation-types'
import {map, orderBy} from "lodash"
import {RESET} from "../../mutation-types"

const mutations = {

    [CREATE_SUBSCRIPTION_REQUEST]: (state) => {
        state.isRequesting = true
    },

    [CREATE_SUBSCRIPTION_REQUEST_SUCCESS]: (state) => {
        state.isRequesting = false
    },

    [CREATE_SUBSCRIPTION_REQUEST_FAILURE]: (state) => {
        state.isRequesting = false
    },

    [DELETE_SUBSCRIPTION_REQUEST]: (state) => {
        state.isRequesting = true
    },

    [DELETE_SUBSCRIPTION_REQUEST_SUCCESS]: (state) => {
        state.isRequesting = false
    },

    [DELETE_SUBSCRIPTION_REQUEST_FAILURE]: (state) => {
        state.isRequesting = false
    },

    [EDIT_SUBSCRIPTION_REQUEST]: (state) => {
        state.isRequesting = true
    },

    [EDIT_SUBSCRIPTION_REQUEST_SUCCESS]: (state) => {
        state.isRequesting = false
    },

    [EDIT_SUBSCRIPTION_REQUEST_FAILURE]: (state) => {
        state.isRequesting = false
    },

    [GET_SUBSCRIPTIONS_REQUEST]: (state) => {
        state.isRequesting = true
    },

    [GET_SUBSCRIPTIONS_REQUEST_SUCCESS]: (state, {_embedded: {subscriptions}}) => {
        state.subscriptions =
            orderBy(
                map(subscriptions, subscription => ({
                    id: subscription.id,
                    startDate: new Date(subscription.startDate),
                    endDate: new Date(subscription.endDate),
                    status: {
                        id: subscription.subscriptionStatusDropdown.id,
                        value: subscription.subscriptionStatusDropdown.value,
                    },
                    paymentInvoiceName: subscription.paymentInvoiceName,
                    completedWorkReportName: subscription.completedWorkReportName,
                    contractName: subscription.contractName,
                    subscriptionType: subscription.subscriptionType,
                })),
                ['startDate'],
                ['desc']
            )
        state.isRequesting = false
    },

    [GET_SUBSCRIPTIONS_REQUEST_FAILURE]: (state) => {
        state.isRequesting = false
    },

    [RESET]: (state) => {
        state.subscriptions = []
    },
}

export default mutations