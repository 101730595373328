const state = {
    organizationId: null,
    actingOnBasis: null,
    bankAccount: null,
    bankAddress: null,
    bankIdentificationCode: null,
    bankName: null,
    city: null,
    contactEmail: null,
    contactFax: null,
    contactName: null,
    contactPhoneNumber: null,
    country: null,
    firstNameHeadOrganization: null,
    fullNameHeadOrganization: null,
    fullNameOrganization: null,
    house: null,
    housing: null,
    lastNameHeadOrganization: null,
    middleNameHeadOrganization: null,
    office: null,
    positionInOrganization: null,
    postcode: null,
    region: null,
    shortNameOrganization: null,
    street: null,
    unp: null,
    isRequesting: false,
}
export default state
