import {
    CHANGE_COLUMN_SORT, REMOVE_COLUMN_SORT,
} from "./mutation-types"
import {RESET} from "../../mutation-types"

const mutations = {

    [CHANGE_COLUMN_SORT]: (state, {sortingPath, sortType, fieldPath}) => {
        state.columnSort = {
            [fieldPath]: {
                sortType,
                sortingPath,
            }
        }
    },

    [REMOVE_COLUMN_SORT]: (state, fieldPath) => {
        // eslint-disable-next-line no-unused-vars
        const {[fieldPath]: removed, ...rest} = state.columnSort
        state.columnSort = rest
    },

    [RESET]: (state) => {
        state.columnSort = {}
    }
}

export default mutations