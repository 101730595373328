import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuelidate from 'vuelidate'
import VueInputMask from "vue-inputmask"
import Modal from './plugins/modal'
import VueIco from 'vue-ico'
import {icons} from './utils/vueIcons'
import {clickOutside} from '@/plugins/clickOutside'
import {getRefreshToken} from "@/utils/auth"
import {homePath} from "@/router/home"
import Toast from 'vue-toastification'
import "vue-toastification/dist/index.css"
import VCalendar from 'v-calendar'
import VuePageTitle from 'vue-page-title'
import VueGtm from "vue-gtm"
import Configuration from "./configuration"
import VueTour from 'vue-tour'
import "vue-tour/dist/vue-tour.css"
import {Popover} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Notifications from 'vue-notification'


console.log(Configuration.value('apiEndpoint'))

Vue.config.productionTip = false

Vue.use(Vuelidate)
Vue.use(VueInputMask.default)
Vue.use(Modal)
Vue.use(Popover)
Vue.use(VueIco, icons)
Vue.use(Toast, {
    position: "top-right",
})
Vue.use(VCalendar)
Vue.use(VuePageTitle, {
    router,
    prefix: 'Bytorg | '
})

Vue.use(VueGtm, {
    id: `${Configuration.value('gtmId')}`,
    vueRouter: router,
    enabled: `${Configuration.value('gtmEnabled') === "true"}`
})

Vue.use(VueTour)

Vue.use(Notifications)

Vue.directive('click-outside', clickOutside)

const init = () => {

    new Vue({
        router,
        store,
        render: h => h(App),
    }).$mount('#app')
}

const refreshToken = getRefreshToken()

if (refreshToken) {
    store.dispatch('auth/refreshTokens', refreshToken)
        .then(() => init())
        .catch(() =>
            router.push(homePath).then(() => init())
        )
} else {
    init()
}

export const eventBus = new Vue()

