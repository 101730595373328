export const usersPath = '/parsers'

const displayName = 'Парсеры'

export default {
    path: usersPath,
    name: 'parsers',
    icon: 'icoStorage',
    display: displayName,
    meta: {
        layout: 'main',
        requiresAuth: true,
        permission: 'ROLE_ADMIN',
        title: displayName,
    },
    component: () => import("@/views/Parsers")
}