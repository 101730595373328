<template>
    <div class="modal-overlay"
         :class="overlayClass"
         v-if="isOpen"
         @click.self="destroyModal">
        <transition name="modal-fade" @after-leave="isOpen=false">
            <div
                    class="modal"
                    v-if="component"
                    :style="{maxWidth: modalProps.width + 'px'}"
            >
                <div
                        class="modal-container"
                        :class="modalProps.class"
                >
                    <div class="modal-header">
                        <h2 v-if="modalProps">{{ modalProps.title }}</h2>
                        <button class="modal-close" type="button" @click="destroyModal">
                            <ico name="icoClose" color="#79919C"></ico>
                        </button>
                    </div>
                    <div :class="['modal-body', {'no-paddings': modalProps.modalBodyNoPaddings}, {'custom-paddings': modalProps.modalBodyCustomPaddings}]">
                        <component :is="component" :componentProps="componentProps"></component>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>

    export default {
        name: "Modal",
        data() {
            return {
                component: null,
                componentProps: null,
                modalProps: null,
                isOpen: false,
            }
        },
        computed: {
            overlayClass() {
                return this.modalProps?.class
            }
        },
        methods: {
            show(component, componentProps, modalProps) {
                this.isOpen = true
                this.modalProps = modalProps
                this.$nextTick(() => {
                    this.component = component
                    this.componentProps = componentProps
                })
            },
            destroyModal() {
                const cmpName = this.component.name
                this.$nextTick(() => {
                    if (cmpName === "purchasesTableCustomFilterModal" && this.$tours['startTour'].isRunning && (this.$tours['startTour'].currentStep > 0 && this.$tours['startTour'].currentStep < 6)) {
                        this.$tours['startTour'].stop()
                        this.$tours['startTour'].start()
                    }
                })
                this.component = null
                this.componentProps = null
                this.modalProps = null
            },
            escapeKeyListener(e) {
                if (e.key === "Escape" && this.isOpen) {
                    this.destroyModal()
                }
            }
        },
        watch: {
            // isOpen(newVal) {
            //     const className = 'no-scroll';
            //     if (newVal) {
            //         document.body.classList.add(className);
            //     } else {
            //         document.body.classList.remove(className);
            //     }
            // }
        },
        created() {
            this.$modal.container = this
            document.addEventListener('keyup', this.escapeKeyListener)
        },
        destroyed() {
            document.removeEventListener('keyup', this.escapeKeyListener)
        },
    }
</script>

<style lang="less" scoped>
    @import '../../assets/less/variables.less';

    .modal-fade-enter-active {
    }

    .modal-fade-leave-active {
    }

    .modal-overlay {
        overflow-x: hidden;
        overflow-y: auto;
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        z-index: 110;
        width: 100%;
        height: 100%;

        &.dark {
            background-color: fade(#ffffff, 75%);

            &::-webkit-scrollbar-thumb {
                border-radius: @br;
                background-color: @bg-color-middle;
                &:hover {
                    background-color: @text-color-lighter;
                }
            }
        }

        &.light {
            background-color: fade(@text-color, 80%);

            &::-webkit-scrollbar-thumb {
                border-radius: @br;
                background-color: fade(@text-color, 40%);
                &:hover {
                    background-color: fade(@text-color, 50%);
                }
            }
        }

        &::-webkit-scrollbar {
            border-radius: @br;
            width: 10px;
            background-color: transparent;
        }
    }

    .modal {
        width: 100%;

    }

    .modal-container {
        margin: 0 auto;
        border-radius: @br;
        width: 100%;
        text-align: left;
    }

    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h2 {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    .modal-close {
        display: flex;
        justify-content: center;
        align-content: center;
        width: 24px;
        height: 24px;
    }

    .modal-container.dark {
        padding: 20px 24px 24px;
        background-color: @bg-color;

        .modal-header {
            margin-bottom: 24px;

            h2 {
                font-weight: 500;
                font-size: 16px;
                line-height: 1.5;
                letter-spacing: 0.3px;
                color: #ffffff;
            }
        }
    }

    .modal-container.light {
        padding: 0;
        background-color: #ffffff;

        .modal-header {
            border-top-left-radius: @br;
            border-top-right-radius: @br;
            padding: 18px 32px;
            background-color: @bg-color-light;

            h2 {
                font-weight: 400;
                font-size: 18px;
                line-height: 1.5;
                letter-spacing: 0.3px;
                color: @text-color-middle;
            }
        }

        .modal-body:not(.no-paddings) {
            padding: 32px 32px 24px;
        }

        .modal-body.custom-paddings{
            padding: 32px 0px 24px 0;
        }
    }
</style>
