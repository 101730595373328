import {
    SET_IMPERSONALIZATION_MODE_ON_REQUEST,
    SET_IMPERSONALIZATION_MODE_ON_REQUEST_SUCCESS,
    SET_IMPERSONALIZATION_MODE_ON_REQUEST_FAILURE,
    SET_IMPERSONALIZATION_MODE_OFF,
} from "./mutation-types"
import {RESET} from "../../mutation-types"

const mutations = {

    [SET_IMPERSONALIZATION_MODE_ON_REQUEST]: (state) => {
        state.isRequesting = true
    },

    [SET_IMPERSONALIZATION_MODE_ON_REQUEST_SUCCESS]: (state, email) => {
        state.mindControlledUser = email
        state.isRequesting = false
    },

    [SET_IMPERSONALIZATION_MODE_ON_REQUEST_FAILURE]: (state) => {
        state.isRequesting = false
    },

    [SET_IMPERSONALIZATION_MODE_OFF]: (state) => {
        state.mindControlledUser = null
    },

    [RESET]: (state) => {
        state.mindControlledUser = null
        state.isRequesting = false
    }
}

export default mutations