import {filter, map} from "lodash"

const getters = {
    subscriptions: (state) => state.subscriptions,
    generalSubscriptions: (state) => map(filter(state.subscriptions, ({subscriptionType}) => subscriptionType === 'GENERAL'), subscription => {
        // eslint-disable-next-line no-unused-vars
        const {subscriptionType, ...rest} = subscription
        return rest
    }),
    isRequesting: (state) => state.isRequesting,
}

export default getters