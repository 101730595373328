import axiosInstance from '@/axios'

const getProfileByEmail = (email) => {

    return axiosInstance.get(`/api/profile/profiles/search/findByEmail?email=${email}&projection=profileProjection`)
}

const updatePhone = (id, phone) => {

    return axiosInstance.patch(`/api/profile/profiles/${id}?projection=profileProjection`, {phone})
}

const updateHintsEnabled = (id, hintsEnabled) => {

    return axiosInstance.patch(`/api/profile/profiles/${id}?projection=profileProjection`, {hintsEnabled})
}

export {
    getProfileByEmail,
    updatePhone,
    updateHintsEnabled
}