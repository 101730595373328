const SET_PAGINATION_TYPES = 'SET_PAGINATION_TYPES'
const SET_PURCHASES_TYPES = 'SET_PURCHASES_TYPES'
const SET_PLACES_TYPES = 'SET_PLACES_TYPES'
const SET_LINE_HEIGHTS_TYPES = 'SET_LINE_HEIGHTS_TYPES'
const SET_SUBSCRIPTION_STATUS_TYPES = 'SET_SUBSCRIPTION_STATUS_TYPES'
const SET_SECTORS_TYPES = 'SET_SECTORS_TYPES'

export {
    SET_PAGINATION_TYPES,
    SET_PURCHASES_TYPES,
    SET_PLACES_TYPES,
    SET_LINE_HEIGHTS_TYPES,
    SET_SUBSCRIPTION_STATUS_TYPES,
    SET_SECTORS_TYPES,
}