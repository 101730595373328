import {
    CHANGE_COLUMN_PROPERTY_REQUEST,
    CHANGE_COLUMN_PROPERTY_REQUEST_SUCCESS,
    CHANGE_COLUMN_PROPERTY_REQUEST_FAILURE,
    CHANGE_PAGINATION_SIZE_REQUEST,
    CHANGE_PAGINATION_SIZE_REQUEST_SUCCESS,
    CHANGE_PAGINATION_SIZE_REQUEST_FAILURE,
    CHANGE_LINE_HEIGHTS_TYPE_REQUEST,
    CHANGE_LINE_HEIGHTS_TYPE_REQUEST_SUCCESS,
    CHANGE_LINE_HEIGHTS_TYPE_REQUEST_FAILURE,
    UPDATE_TABLE_SETTING_REQUEST,
    UPDATE_TABLE_SETTING_REQUEST_FAILURE,
    UPDATE_TABLE_SETTING_REQUEST_SUCCESS,
    GET_PURCHASES_TABLE_SETTING_REQUEST,
    GET_PURCHASES_TABLE_SETTING_REQUEST_SUCCESS,
    GET_PURCHASES_TABLE_SETTING_REQUEST_FAILURE,
    UPDATE_IS_ACTIVE_SEARCH_REQUEST,
    UPDATE_IS_ACTIVE_SEARCH_REQUEST_SUCCESS,
    UPDATE_IS_ACTIVE_SEARCH_REQUEST_FAILURE,
    UPDATE_IS_KEYWORD_SHOWN_REQUEST,
    UPDATE_IS_KEYWORD_SHOWN_REQUEST_SUCCESS,
    UPDATE_IS_KEYWORD_SHOWN_REQUEST_FAILURE,
} from './mutations-types'
import {RESET} from "../../mutation-types"
import {keyBy, unset, map} from "lodash"
import {mapColumnSetting} from "@/utils/mapper"

const mutations = {

    [UPDATE_TABLE_SETTING_REQUEST]: (state) => {
        state.isRequesting = true
    },

    [UPDATE_TABLE_SETTING_REQUEST_SUCCESS]: (state, purchasesTableColumns) => {
        state.purchasesTableColumns = purchasesTableColumns
        state.isRequesting = false
    },

    [UPDATE_TABLE_SETTING_REQUEST_FAILURE]: (state) => {
        state.isRequesting = false
    },

    [CHANGE_COLUMN_PROPERTY_REQUEST]: (state) => {
        state.isRequesting = true
    },

    [CHANGE_COLUMN_PROPERTY_REQUEST_SUCCESS]: (state, {columnName, columnProperty, value}) => {
        state.purchasesTableColumns[columnName][columnProperty] = value
        state.isRequesting = false
    },

    [CHANGE_COLUMN_PROPERTY_REQUEST_FAILURE]: (state) => {
        state.isRequesting = false
    },

    [CHANGE_PAGINATION_SIZE_REQUEST]: (state) => {
        state.isRequesting = true
    },

    [CHANGE_PAGINATION_SIZE_REQUEST_SUCCESS]: (state, paginationSetting) => {
        unset(paginationSetting, '_links')

        state.pagination = paginationSetting
        state.isRequesting = false
    },

    [CHANGE_PAGINATION_SIZE_REQUEST_FAILURE]: (state) => {
        state.isRequesting = false
    },

    [CHANGE_LINE_HEIGHTS_TYPE_REQUEST]: (state) => {
        state.isRequesting = true
    },

    [CHANGE_LINE_HEIGHTS_TYPE_REQUEST_SUCCESS]: (state, rowTypeSetting) => {
        unset(rowTypeSetting, '_links')

        state.lineHeightsType = rowTypeSetting
        state.isRequesting = false
    },

    [CHANGE_LINE_HEIGHTS_TYPE_REQUEST_FAILURE]: (state) => {
        state.isRequesting = false
    },

    [GET_PURCHASES_TABLE_SETTING_REQUEST]: (state) => {
        state.isRequesting = true
    },

    [GET_PURCHASES_TABLE_SETTING_REQUEST_SUCCESS]: (state, {columnSettings, paginationSetting, rowTypeSetting, id, isActiveSearch, isKeywordShown}) => {
        unset(paginationSetting, '_links')
        unset(rowTypeSetting, '_links')

        state.purchasesTableColumns = keyBy(map(columnSettings, mapColumnSetting), 'field')
        state.lineHeightsType = rowTypeSetting
        state.pagination = paginationSetting
        state.tableSettingId = id
        state.isActiveSearch = isActiveSearch
        state.isKeywordShown = isKeywordShown
        state.isRequesting = false
    },

    [GET_PURCHASES_TABLE_SETTING_REQUEST_FAILURE]: (state) => {
        state.isRequesting = false
    },

    [UPDATE_IS_ACTIVE_SEARCH_REQUEST]: (state) => {
        state.isRequesting = true
    },

    [UPDATE_IS_ACTIVE_SEARCH_REQUEST_SUCCESS]: (state, isActiveSearch) => {
        state.isActiveSearch = isActiveSearch
        state.isRequesting = false
    },

    [UPDATE_IS_ACTIVE_SEARCH_REQUEST_FAILURE]: (state) => {
        state.isRequesting = false
    },

    [UPDATE_IS_KEYWORD_SHOWN_REQUEST]: (state) => {
        state.isRequesting = true
    },

    [UPDATE_IS_KEYWORD_SHOWN_REQUEST_SUCCESS]: (state, isKeywordShown) => {
        state.isKeywordShown = isKeywordShown
        state.isRequesting = false
    },

    [UPDATE_IS_KEYWORD_SHOWN_REQUEST_FAILURE]: (state) => {
        state.isRequesting = false
    },

    [RESET]: (state) => {
        state.pagination = null
        state.lineHeightsType = null
        state.purchasesTableColumns = null
        state.tableSettingId = null
        state.isActiveSearch = null
        state.isKeywrodShown = null
        state.isRequesting = false
    }
}

export default mutations
