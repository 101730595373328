import {RESET} from "../../mutation-types"
import {
    LOGS_REQUEST,
    LOGS_REQUEST_FAILURE,
    LOGS_REQUEST_SUCCESS,
    DELETE_LOGS_BY_SOURCE_REQUEST,
    DELETE_LOGS_BY_SOURCE_REQUEST_SUCCESS,
    DELETE_LOGS_BY_SOURCE_REQUEST_FAILURE,
} from "@/store/modules/parsers/mutation-types"
import {
    ICE_TRADE,
    GOS_ZAKUPKI,
    BUTB,
    BELOIL,
    BUTB_PPT,
    NAFTAN,
    MINSKTRANS,
    BELARUSKALI,
    BIDMART
} from "@/utils/parsersNames"
import {mapValues, map} from "lodash"

const mutations = {
    [LOGS_REQUEST]: (state, source) => {
        state[source].isRequesting = true
    },
    
    [LOGS_REQUEST_FAILURE]: (state, source) => {
        state[source].isRequesting = false
    },
    
    [LOGS_REQUEST_SUCCESS]: (state, {source, data: {_embedded: {parserLogs}, _links, page}}) => {
        state[source].logs = map(parserLogs, it => ({...it, proposal: JSON.parse(it.proposal)}))
        state[source].links = mapValues(_links, (v) => new URLSearchParams(new URL(v.href).search.substring(1)).get('page'))
        state[source].page = page
        state[source].isRequesting = false
    },

    [DELETE_LOGS_BY_SOURCE_REQUEST]: (state, source) => {
        state[source].isRequesting = true
    },

    [DELETE_LOGS_BY_SOURCE_REQUEST_FAILURE]: (state, source) => {
        state[source].isRequesting = false
    },

    [DELETE_LOGS_BY_SOURCE_REQUEST_SUCCESS]: (state, source) => {
        state[source].isRequesting = false
    },
    
    [RESET]: (state) => {
        state[ICE_TRADE].logs = []
        state[ICE_TRADE].links = {}
        state[ICE_TRADE].page = {}
        state[BUTB].logs = []
        state[BUTB].links = {}
        state[BUTB].page = {}
        state[GOS_ZAKUPKI].logs = []
        state[GOS_ZAKUPKI].links = {}
        state[GOS_ZAKUPKI].page = {}
        state[BELOIL].logs = []
        state[BELOIL].links = {}
        state[BELOIL].page = {}
        state[BUTB_PPT].logs = []
        state[BUTB_PPT].links = {}
        state[BUTB_PPT].page = {}
        state[NAFTAN].logs = []
        state[NAFTAN].links = {}
        state[NAFTAN].page = {}
        state[MINSKTRANS].logs = []
        state[MINSKTRANS].links = {}
        state[MINSKTRANS].page = {}
        state[BELARUSKALI].logs = []
        state[BELARUSKALI].links = {}
        state[BELARUSKALI].page = {}
        state[BIDMART].logs = []
        state[BIDMART].links = {}
        state[BIDMART].page = {}
    }
}

export default mutations