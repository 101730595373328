import {
    LOGIN_USER_REQUEST,
    LOGIN_USER_REQUEST_FAILURE,
    LOGIN_USER_REQUEST_SUCCESS,
    LOGOUT_USER,
    REFRESH_TOKENS_REQUEST,
    REFRESH_TOKENS_REQUEST_SUCCESS,
    REFRESH_TOKENS_REQUEST_FAILURE,
} from "./mutation-types"
import {RESET} from "../../mutation-types"
import jwtDecode from "jwt-decode"

export default ({

    [LOGIN_USER_REQUEST]: (state) => {
        state.isRequesting = true
    },

    [LOGIN_USER_REQUEST_SUCCESS]: (state, {access_token, refresh_token}) => {
        const decodedToken = jwtDecode(access_token)
        state.isAuthenticated = true
        state.refreshToken = refresh_token
        state.accessToken = access_token
        state.authorities = decodedToken.authorities
        state.email = decodedToken.user_name
        state.isRequesting = false
    },

    [LOGIN_USER_REQUEST_FAILURE]: (state) => {
        state.isRequesting = false
        state.isAuthenticated = false
    },

    [LOGOUT_USER]: (state) => {
        state.isAuthenticated = false
        state.refreshToken = null
        state.accessToken = null
        state.authorities = null
        state.email = null
    },

    [REFRESH_TOKENS_REQUEST]: (state) => {
        state.isRequesting = true
    },

    [REFRESH_TOKENS_REQUEST_SUCCESS]: (state, {access_token, refresh_token}) => {
        const decodedToken = jwtDecode(access_token)
        state.accessToken = access_token
        state.refreshToken = refresh_token
        state.isAuthenticated = true
        state.authorities = decodedToken.authorities
        state.email = decodedToken.user_name
        state.isRequesting = false
    },

    [REFRESH_TOKENS_REQUEST_FAILURE]: (state) => {
        state.isAuthenticated = false
        state.isRequesting = false
    },

    [RESET]: (state) => {
        state.accessToken = null
        state.refreshToken = null
        state.isAuthenticated = false
        state.authorities = null
        state.email = null
    }
})
