import axiosInstance from '@/axios'

const createProxy = (proxyData) => {
    
    return axiosInstance.post(`/api/parsing/parserProxies`, proxyData)
}

const deleteProxy = (proxyId) => {
    
    return axiosInstance.delete(`/api/parsing/parserProxies/${proxyId}`)
}

const editProxy = (proxyId, proxyData) => {
    
    return axiosInstance.patch(`/api/parsing/parserProxies/${proxyId}`, proxyData)
}

const getProxies = () => {
    
    return axiosInstance.get(`/api/parsing/parserProxies?projection=parserProxyProjection`)
}

export {
    createProxy,
    deleteProxy,
    editProxy,
    getProxies,
}