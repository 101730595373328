const getters = {

    organizationId: (state) => state.organizationId,
    actingOnBasis: (state) => state.actingOnBasis,
    bankAccount: (state) => state.bankAccount,
    bankAddress: (state) => state.bankAddress,
    bankIdentificationCode: (state) => state.bankIdentificationCode,
    bankName: (state) => state.bankName,
    city: (state) => state.city,
    contactEmail: (state) => state.contactEmail,
    contactFax: (state) => state.contactFax,
    contactName: (state) => state.contactName,
    contactPhoneNumber: (state) => state.contactPhoneNumber,
    country: (state) => state.country,
    firstNameHeadOrganization: (state) => state.firstNameHeadOrganization,
    fullNameHeadOrganization: (state) => state.fullNameHeadOrganization,
    fullNameOrganization: (state) => state.fullNameOrganization,
    house: (state) => state.house,
    housing: (state) => state.housing,
    lastNameHeadOrganization: (state) => state.lastNameHeadOrganization,
    middleNameHeadOrganization: (state) => state.middleNameHeadOrganization,
    office: (state) => state.office,
    positionInOrganization: (state) => state.positionInOrganization,
    postcode: (state) => state.postcode,
    region: (state) => state.region,
    shortNameOrganization: (state) => state.shortNameOrganization,
    street: (state) => state.street,
    unp: (state) => state.unp,
    isRequesting: (state) => state.isRequesting,
}

export default getters
