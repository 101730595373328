import {
    icoClose,
    icoVisibility,
    icoVisibilityOff,
    icoSettings,
    icoPerson,
    icoSearch,
    icoMenu,
    icoExitToApp,
    icoAccountCircle,
    icoAvTimer,
    icoArrowDropDown,
    icoChevronRight,
    icoChevronLeft,
    icoDragHandle,
    icoNavigation,
    icoCancel,
    icoStar,
    icoStarBorder,
    icoSkipNext,
    icoSkipPrevious,
    icoNavigateNext,
    icoNavigateBefore,
    icoHelpOutline,
    icoOpenInNew,
    icoMoreVert,
    icoEdit,
    icoDelete,
    icoErrorOutline,
    icoPeople,
    icoInfo,
    icoSort,
    icoArrowUpward,
    icoArrowDownward,
    icoStorage,
    icoCheckCircle,
    icoHelp,
    icoAssignment,
    icoCheck,
    icoDone,
    icoDoneAll,
    icoFlag,
    icoMail,
    icoError
} from 'vue-ico'

export const icons = {
    icoClose,
    icoVisibility,
    icoVisibilityOff,
    icoSettings,
    icoPerson,
    icoSearch,
    icoMenu,
    icoExitToApp,
    icoAccountCircle,
    icoAvTimer,
    icoArrowDropDown,
    icoChevronRight,
    icoChevronLeft,
    icoNavigation,
    icoCancel,
    icoDragHandle,
    icoStar,
    icoStarBorder,
    icoSkipNext,
    icoSkipPrevious,
    icoNavigateNext,
    icoNavigateBefore,
    icoHelpOutline,
    icoOpenInNew,
    icoMoreVert,
    icoEdit,
    icoDelete,
    icoErrorOutline,
    icoPeople,
    icoInfo,
    icoSort,
    icoArrowUpward,
    icoArrowDownward,
    icoStorage,
    icoCheckCircle,
    icoHelp,
    icoAssignment,
    icoCheck,
    icoDone,
    icoDoneAll,
    icoFlag,
    icoMail,
    icoError
}

