const CREATE_PROXY_REQUEST = 'CREATE_PROXY_REQUEST'
const CREATE_PROXY_REQUEST_SUCCESS = 'CREATE_PROXY_REQUEST_SUCCESS'
const CREATE_PROXY_REQUEST_FAILURE = 'CREATE_PROXY_REQUEST_FAILURE'
const EDIT_PROXY_REQUEST = 'EDIT_PROXY_REQUEST'
const EDIT_PROXY_REQUEST_SUCCESS = 'EDIT_PROXY_REQUEST_SUCCESS'
const EDIT_PROXY_REQUEST_FAILURE = 'EDIT_PROXY_REQUEST_FAILURE'
const DELETE_PROXY_REQUEST = 'DELETE_PROXY_REQUEST'
const DELETE_PROXY_REQUEST_SUCCESS = 'DELETE_PROXY_REQUEST_SUCCESS'
const DELETE_PROXY_REQUEST_FAILURE = 'DELETE_PROXY_REQUEST_FAILURE'
const GET_PROXIES_REQUEST = 'GET_PROXIES_REQUEST'
const GET_PROXIES_REQUEST_SUCCESS = 'GET_PROXIES_REQUEST_SUCCESS'
const GET_PROXIES_REQUEST_FAILURE = 'GET_PROXIES_REQUEST_FAILURE'

export {
    CREATE_PROXY_REQUEST,
    CREATE_PROXY_REQUEST_SUCCESS,
    CREATE_PROXY_REQUEST_FAILURE,
    DELETE_PROXY_REQUEST,
    DELETE_PROXY_REQUEST_SUCCESS,
    DELETE_PROXY_REQUEST_FAILURE,
    EDIT_PROXY_REQUEST,
    EDIT_PROXY_REQUEST_SUCCESS,
    EDIT_PROXY_REQUEST_FAILURE,
    GET_PROXIES_REQUEST,
    GET_PROXIES_REQUEST_SUCCESS,
    GET_PROXIES_REQUEST_FAILURE,
}