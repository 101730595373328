import api from "../../../application"
import {
    DELETE_FILTER_TAB_FAILURE,
    DELETE_FILTER_TAB_REQUEST,
    DELETE_FILTER_TAB_SUCCESS,
    EDIT_FILTER_TAB_REQUEST,
    EDIT_FILTER_TAB_REQUEST_FAILURE,
    EDIT_FILTER_TAB_REQUEST_SUCCESS,
    SAVE_FILTER_TAB_REQUEST,
    SAVE_FILTER_TAB_REQUEST_FAILURE,
    SAVE_FILTER_TAB_REQUEST_SUCCESS,
    SET_CURRENT_TAB,
    SET_FILTER_TABS,
    SOCKET_UPDATE_TAB_FILTER,
    UPDATE_ALL_FILTER_TABS_REQUEST,
    UPDATE_ALL_FILTER_TABS_REQUEST_FAILURE,
    UPDATE_ALL_FILTER_TABS_REQUEST_SUCCESS,
} from "./mutation-types"
import {find, map} from "lodash"

const actions = {

    getFilterTabs: async ({commit}, profileId) => {
        try {
            const {data} = await api.filtersTabs.getFilterTabs(profileId)
            commit(SET_FILTER_TABS, data._embedded.filters)
        } catch (e) {
            throw new Error(e.response.data.errorMessage)
        }
    },

    createFilterTab: async ({commit}, formData) => {
        commit(SAVE_FILTER_TAB_REQUEST)
        try {
            await api.filtersTabs.createFilterTab(formData)
            commit(SAVE_FILTER_TAB_REQUEST_SUCCESS)
        } catch (e) {
            commit(SAVE_FILTER_TAB_REQUEST_FAILURE)
            throw new Error(e.response.data.errorMessage)
        }
    },

    deleteFilterTab: async ({commit}, filterId) => {
        commit(DELETE_FILTER_TAB_REQUEST)
        try {
            await api.filtersTabs.deleteFilterTab(filterId)
            commit(DELETE_FILTER_TAB_SUCCESS)
        } catch (e) {
            commit(DELETE_FILTER_TAB_FAILURE)
            throw new Error(e.response.data.errorMessage)
        }
    },

    editFilterTab: async ({commit}, {filterId, filterData}) => {
        commit(EDIT_FILTER_TAB_REQUEST)

        try {
            const {data} = await api.filtersTabs.editFilterTab(filterId, filterData)
            commit(EDIT_FILTER_TAB_REQUEST_SUCCESS, data)
        } catch (e) {
            commit(EDIT_FILTER_TAB_REQUEST_FAILURE)
            throw new Error(e.response.data.errorMessage)
        }
    },

    updateAllFilterTabs: ({commit}, {newFilterTabs, profileId}) => {
        commit(UPDATE_ALL_FILTER_TABS_REQUEST)

        try {
            api.filtersTabs.updateAllFilterTabs(
                map(newFilterTabs, (it, idx) => ({...it, tabIndex: idx})),
                profileId
            )
            commit(UPDATE_ALL_FILTER_TABS_REQUEST_SUCCESS, newFilterTabs)
        } catch (e) {
            commit(UPDATE_ALL_FILTER_TABS_REQUEST_FAILURE)
            throw new Error(e.response.data.errorMessage)
        }
    },

    updateFilterTab: ({commit, getters}, payload) => {

        if (find(getters['filterTabs'], ['id', payload.filterId])) {
            commit(SOCKET_UPDATE_TAB_FILTER, payload)
        }

    },

    setCurrentTab: ({dispatch}, filterTab) => {
        dispatch('columnsFilters/resetColumnsFilters', {}, {root: true})
        dispatch('columnsSort/resetColumnSort', {}, {root: true})
        dispatch('setZeroAllLots', filterTab)
    },

    setZeroAllLots: ({commit, rootGetters}, filterTab) => {
        if (rootGetters["impersonalization/mindControlledUser"]) {
            commit(SET_CURRENT_TAB, filterTab)
            return
        }
        commit(SET_CURRENT_TAB, {...filterTab, newLots: 0})
    },
}

export default actions