import api from "@/application"
import {
    LOGS_REQUEST,
    LOGS_REQUEST_FAILURE,
    LOGS_REQUEST_SUCCESS,
    DELETE_LOGS_BY_SOURCE_REQUEST,
    DELETE_LOGS_BY_SOURCE_REQUEST_SUCCESS,
    DELETE_LOGS_BY_SOURCE_REQUEST_FAILURE,
} from "@/store/modules/parsers/mutation-types"

const actions = {
    
    getLogs: async ({commit}, {source, searchParams}) => {
        commit(LOGS_REQUEST, source)
        try {
            const {data} = await api.parsers.getLogsBySource(source, searchParams)
            commit(LOGS_REQUEST_SUCCESS, {source, data})
        } catch (e) {
            commit(LOGS_REQUEST_FAILURE, source)
            throw new Error(e.response.data.errorMessage)
        }
    },

    deleteParserLogsBySource: async ({commit}, source) => {
        commit(DELETE_LOGS_BY_SOURCE_REQUEST, source)
        try {
            await api.parsers.deleteAllLogBySource(source)
            commit(DELETE_LOGS_BY_SOURCE_REQUEST_SUCCESS, source)
        } catch (e) {
            commit(DELETE_LOGS_BY_SOURCE_REQUEST_FAILURE, source)
            throw new Error(e.response.data.errorMessage)
        }
    },
}

export default actions