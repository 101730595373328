import {isEmpty} from "lodash"

const getters = {
    logs: (state) => (source) => state[source].logs,
    links: (state) => (source) => state[source].links,
    page: (state) => (source) => state[source].page,
    isParserLogsEmptyBySource: (state) => (source) => isEmpty(state[source].logs)

}

export default getters