import axiosInstance from '@/axios'
import {createEqualParam, createParams} from "@/utils/searchQueryParamBuilder"

const sortUsersByOnline = (size, isOnline, role, page) => {
    return axiosInstance.get(`api/profile/admin/${role}/online${
      createParams(
        createEqualParam('size', size),
        createEqualParam('page', page),
        createEqualParam('isOnline', isOnline),
      )
    }`)
}
export {
  sortUsersByOnline,
}