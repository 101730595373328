<template>
    <div id="app">
        <component :is="layout"/>
        <modal></modal>
        <div style="display: none">
            <div id="purchase-key-words-hint"></div>
            <div id="purchase-exclude-words-hint"></div>
            <div id="purchase-region-words-hint"></div>
        </div>
        <notifications class="notifications-group" group="templateNotification" position="bottom right" width = "430px" bottom="10px">
            <template slot="body" slot-scope="{ item, close }">
                <div class="my-notification">
                    <div class="header">
                        <router-link
                            to="/notifications"
                            target="_blank"
                            class="popupNav"
                        >  
                            <div class="title">
                                <ico v-if="item.data.priority === 'IMPORTANT'" name="icoError" color="#EC3323" size="30px"></ico>
                                <ico v-if="item.data.priority === 'SUCCESS'" name="icoCheckCircle" color="#12AE0E" size="30px"></ico>
                                <p @click="close" v-on:click="markAllNotificationsCheckedFn(profileId)" v-html="JSON.parse(item.title.replaceAll(/\\n/gm, '<br>'))"></p>
                            </div>
                        </router-link>

                        <button class="close-button" @click="close" v-on:click="markNotificationCheckedFn(profileId, [item.data.notificationId])">
                            <ico name="icoClose" color="#BFC2C4"></ico>
                        </button>
                    </div>
                    <router-link
                            to="/notifications"
                            target="_blank"
                            class="popupNav"
                        > 
                        <p class="text" @click="close" v-on:click="markAllNotificationsCheckedFn(profileId)" v-html="JSON.parse(item.text.replaceAll(/\\n/gm, '<br>'))"></p>
                    </router-link>
                    <div class="action">
                        <p class="action-readed" v-if="!item.data.active" style="cursor: pointer; user-select: none;" v-on:click="markNotificationAsReaded(profileId, item.data.notificationId)" @click="close">Пометить прочитанным</p>
                        <router-link
                            to="/profile/subscription"
                            target="_blank"
                        >   
                            <button v-if="item.data.active" class="close  btn btn-middle" @click="close">
                                Продлить подписку
                            </button>
                        </router-link>
                    </div>
                </div>
            </template>
        </notifications>
    </div>
</template>

<script>
    import Modal from "./components/common/Modal"
    import MainLayout from "./layouts/MainLayout"
    import AuthLayout from "./layouts/AuthLayout"
    import BackgroundOnlyLayout from "./layouts/BackgroundOnlyLayout"
    import PrivacyPolicyAndTermOfUseLayout from "./layouts/PrivacyPolicyAndTermOfUseLayout"
    import {mapGetters} from "vuex"
    import {connectWS} from "@/utils/stompClient"
    import { markNotificationChecked, markNotificationReaded } from "./application/requests/notificationsRequests"
    import { mapActions } from "vuex/dist/vuex.common.js"
    import Vue from "vue"


    export default {
        name: 'App',
        components: {
            Modal,
            MainLayout,
            AuthLayout,
            BackgroundOnlyLayout,
            PrivacyPolicyAndTermOfUseLayout,
        },
        computed: {
            ...mapGetters('purchasesTableSettings', ['tableSettingId']),
            ...mapGetters('auth', ['isAuthenticated']),
            ...mapGetters('profile', ['profileId']),
            ...mapGetters('notifications', ['displayedNotifications']),
            layout() {
                return (this.$route.meta.layout || 'auth') + '-layout'
            },
            
        },
        methods: {
            ...mapActions('notifications', ['getAllNotifications', 'getNewNotifications']),

            async markNotificationAsReaded(profileId, notificationId){
                await this.markNotificationCheckedFn(profileId, [notificationId])
                await markNotificationReaded(profileId, notificationId)
                await this.getAllNotifications(profileId)
                await this.getNewNotifications(profileId)
            },

            markNotificationCheckedFn(id, idNotification){
                markNotificationChecked(id, idNotification)
            },

            markAllNotificationsCheckedFn(id){
                this.displayedNotifications.forEach((item) => markNotificationChecked(id, [item.id]))
                Vue.notify({
                group: 'templateNotification',
                clean: true,
            }) 
            }
        },
        created() {

            if (this.isAuthenticated) {
                connectWS()
            }
        },
    }
</script>
<style lang="less">
    @import (css) url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800&family=Roboto:wght@400;500&display=swap');
    @import 'assets/less/commonStyles.less';
    @import 'assets/less/notification.less';
</style>
