import axiosInstance from '@/axios'
import {createParams, createEqualParam} from "@/utils/searchQueryParamBuilder"


const getLogsBySource = (source, {page, size}) => {
    return axiosInstance.get(
        `api/logging/parserLogs/search/findAllBySource${createParams(
            createEqualParam('source', source),
            createEqualParam('page', page),
            createEqualParam('size', size),
            createEqualParam('sort', 'dateAdded,DESC'),
            createEqualParam('timeout', 120000)
        )}`
    )
}

const deleteAllLogBySource = (source) => {

    return axiosInstance.delete(`/api/logging/parserLogs/deleteAllBySource?source=${source}`)
}

export {
    getLogsBySource,
    deleteAllLogBySource,
}