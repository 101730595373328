import axiosInstance from '@/axios'

const addRole = (roleId, email) => {

    return axiosInstance.post(`/api/profile/admin/roles/${roleId}/users/${email}`)
}

const deleteRole = (roleId, email) => {

    return axiosInstance.delete(`/api/profile/admin/roles/${roleId}/users/${email}`)
}

export {
    addRole,
    deleteRole,
}