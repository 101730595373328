export const homePath = '/'

const displayName = 'Главная'

export default {
    path: homePath,
    name: 'home',
    meta: {
        layout: 'auth',
        title: displayName
    },
    component: () => import("@/views/Home")
}