const getText = (iNumber, aEndings) =>  {
    const num = iNumber % 100

    if (num >= 11 && num <= 19) {
        return aEndings[2]
    } else {
        const i = num % 10

        switch (i) {
            case (1): return aEndings[0]
            case (2):
            case (3):
            case (4): return aEndings[1]
            default: return aEndings[2]
        }
    }
}

const wordsForm = {
    days : ['день', 'дня', 'дней'],
}

export const getTranslateDays = (iNumber) => getText(iNumber, wordsForm.days)