export const systemNotificationsPath = 'system'

const displayName = 'Системные'

export default {
    name: 'systemNotifications',
    display: 'Системные',
    path: systemNotificationsPath,
    meta: {
        layout: 'main',
        requiresAuth: true,
        title: displayName,
        permission: ['ROLE_ADMIN', 'ROLE_MANAGER'],
    },
    component: () => import("@/views/Notifications/components/SystemNotifications")
}