import {
    CHANGE_COLUMN_PROPERTY_REQUEST,
    CHANGE_COLUMN_PROPERTY_REQUEST_SUCCESS,
    CHANGE_COLUMN_PROPERTY_REQUEST_FAILURE,
    CHANGE_LINE_HEIGHTS_TYPE_REQUEST,
    CHANGE_LINE_HEIGHTS_TYPE_REQUEST_FAILURE,
    CHANGE_LINE_HEIGHTS_TYPE_REQUEST_SUCCESS,
    CHANGE_PAGINATION_SIZE_REQUEST,
    CHANGE_PAGINATION_SIZE_REQUEST_FAILURE,
    CHANGE_PAGINATION_SIZE_REQUEST_SUCCESS,
    GET_PURCHASES_TABLE_SETTING_REQUEST,
    GET_PURCHASES_TABLE_SETTING_REQUEST_FAILURE,
    GET_PURCHASES_TABLE_SETTING_REQUEST_SUCCESS,
    UPDATE_IS_ACTIVE_SEARCH_REQUEST,
    UPDATE_IS_ACTIVE_SEARCH_REQUEST_FAILURE,
    UPDATE_IS_ACTIVE_SEARCH_REQUEST_SUCCESS,
    UPDATE_TABLE_SETTING_REQUEST,
    UPDATE_TABLE_SETTING_REQUEST_FAILURE,
    UPDATE_TABLE_SETTING_REQUEST_SUCCESS,
    UPDATE_IS_KEYWORD_SHOWN_REQUEST,
    UPDATE_IS_KEYWORD_SHOWN_REQUEST_SUCCESS,
    UPDATE_IS_KEYWORD_SHOWN_REQUEST_FAILURE,
} from './mutations-types'
import api from "@/application"
import {isEmpty, values} from "lodash"
import {purchasesPath} from "@/router/purchases"
import router from "@/router"

const actions = {

    getPurchasesTableSetting: async ({commit}, profileId) => {
        commit(GET_PURCHASES_TABLE_SETTING_REQUEST)
        try {
            const {data} = await api.tableSetting.getTableSetting(profileId)
            commit(GET_PURCHASES_TABLE_SETTING_REQUEST_SUCCESS, data)
        } catch (e) {
            commit(GET_PURCHASES_TABLE_SETTING_REQUEST_FAILURE)
            throw new Error(e.response.data.errorMessage)
        }
    },

    updatePurchaseTableColumns: async ({commit, getters}, columns) => {

        commit(UPDATE_TABLE_SETTING_REQUEST)

        try {
            commit(UPDATE_TABLE_SETTING_REQUEST_SUCCESS, columns)
            const tableSetting = {
                columnSettings: values(columns),
            }
            await api.tableSetting.updateTableSetting(getters['tableSettingId'], tableSetting)
        } catch (e) {
            commit(UPDATE_TABLE_SETTING_REQUEST_FAILURE)
            throw new Error(e)
        }
    },

    updatePurchaseTableColumn: async ({commit, getters}, columnData) => {

        commit(CHANGE_COLUMN_PROPERTY_REQUEST)

        try {
            const columnSetting = getters['purchasesTableColumns'][columnData.columnName]
            const {data} = await api.columnSetting.editColumnSetting(columnSetting.id, {
                [columnData.columnProperty]: columnData.value
            })
            commit(CHANGE_COLUMN_PROPERTY_REQUEST_SUCCESS, {
                columnName: data.field,
                columnProperty: columnData.columnProperty,
                value: data[columnData.columnProperty],
            })
        } catch (e) {
            commit(CHANGE_COLUMN_PROPERTY_REQUEST_FAILURE)
            throw new Error(e.response.data.errorMessage)
        }

    },

    updatePaginationSize: async ({commit, getters}, pagination) => {
        commit(CHANGE_PAGINATION_SIZE_REQUEST)

        try {
            const {data} = await api.tableSetting.editTableSetting(getters['tableSettingId'], {paginationSetting: `/${pagination.id}`})
            commit(CHANGE_PAGINATION_SIZE_REQUEST_SUCCESS, data.paginationSetting)
        } catch (e) {
            commit(CHANGE_PAGINATION_SIZE_REQUEST_FAILURE)
            throw new Error(e.response.data.errorMessage)
        }
    },

    updateLineHeightsType: async ({commit, getters}, lineHeightsType) => {
        commit(CHANGE_LINE_HEIGHTS_TYPE_REQUEST)

        try {
            const {data} = await api.tableSetting.editTableSetting(getters['tableSettingId'], {rowTypeSetting: `/${lineHeightsType.id}`})
            commit(CHANGE_LINE_HEIGHTS_TYPE_REQUEST_SUCCESS, data.rowTypeSetting)
        } catch (e) {
            commit(CHANGE_LINE_HEIGHTS_TYPE_REQUEST_FAILURE)
            throw new Error(e.response.data.errorMessage)
        }
    },

    updateIsActiveSearch: async ({commit, getters, dispatch, rootGetters}, {isActiveSearch, routeId, routeQuery}) => {
        commit(UPDATE_IS_ACTIVE_SEARCH_REQUEST)

        try {
            const {data} = await api.tableSetting.editTableSetting(getters['tableSettingId'], {isActiveSearch})
            commit(UPDATE_IS_ACTIVE_SEARCH_REQUEST_SUCCESS, data.isActiveSearch)
            const currentTab = rootGetters['filterTabs/currentTab']
            if (parseInt(routeId) === currentTab.id && isEmpty(routeQuery)) {
                dispatch('filterTabs/setZeroAllLots', currentTab, {root: true})
                return
            }
            router.push(`${purchasesPath}/${currentTab.id}`)
            dispatch('filterTabs/setZeroAllLots', currentTab, {root: true})

        } catch (e) {
            commit(UPDATE_IS_ACTIVE_SEARCH_REQUEST_FAILURE)
            throw new Error(e.response.data.errorMessage)
        }
    },

    updateIsKeywordShown: async ({commit, getters}, isKeywordShown) => {
        commit(UPDATE_IS_KEYWORD_SHOWN_REQUEST)

        try {
            const {data} = await api.tableSetting.editTableSetting(getters['tableSettingId'], {isKeywordShown})
            commit(UPDATE_IS_KEYWORD_SHOWN_REQUEST_SUCCESS, data.isKeywordShown)
        } catch (e) {
            commit(UPDATE_IS_KEYWORD_SHOWN_REQUEST_FAILURE)
            throw new Error(e.response.data.errorMessage)
        }
    },
}
export default actions
