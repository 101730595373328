import emailNotifications from "./emailNotifications";
import historyNotifications from "./historyNotifications";
import sendNotifications from "./sendNotifications";
import systemNotifications from "./systemNotifications";

export default [
    sendNotifications,
    emailNotifications,
    historyNotifications,
    systemNotifications,
]