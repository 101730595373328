<template>
    <div class="modal-content">
        <OrganizationForm
                :isModalForm="true"
                :btn-text="'Продлить подписку'"
                :call-back-func="componentProps.createSubscription"
        />
    </div>
</template>

<script>
    import OrganizationForm from '@/components/profilePage/OrganizationForm'

    export default {
        name: "SubscriptionModal",
        data() {
            return {

            }
        },
        props: ['componentProps'],
        components: {
            OrganizationForm
        }
    }
</script>

<style lang="less" scoped>
</style>
