import {
    SET_ORGANIZATION,
    ORGANIZATION_REQUEST,
    ORGANIZATION_REQUEST_FAILURE,
    EDIT_ORGANIZATION_REQUEST,
    EDIT_ORGANIZATION_REQUEST_FAILURE,
    EDIT_ORGANIZATION_REQUEST_SUCCESS,
    SAVE_ORGANIZATION_REQUEST,
    SAVE_ORGANIZATION_REQUEST_FAILURE,
    SAVE_ORGANIZATION_REQUEST_SUCCESS,
} from './mutation-types'
import {RESET} from "../../mutation-types"

const setOrganization = (state, payload) => {
    state.organizationId = payload?.id
    state.actingOnBasis = payload?.actingOnBasis
    state.bankAccount = payload?.bankAccount
    state.bankAddress = payload?.bankAddress
    state.bankIdentificationCode = payload?.bankIdentificationCode
    state.bankName = payload?.bankName
    state.city = payload?.city
    state.contactEmail = payload?.contactEmail
    state.contactFax = payload?.contactFax
    state.contactName = payload?.contactName
    state.contactPhoneNumber = payload?.contactPhoneNumber
    state.country = payload?.country
    state.firstNameHeadOrganization = payload?.firstNameHeadOrganization
    state.fullNameHeadOrganization = payload?.fullNameHeadOrganization
    state.fullNameOrganization = payload?.fullNameOrganization
    state.house = payload?.house
    state.housing = payload?.housing
    state.lastNameHeadOrganization = payload?.lastNameHeadOrganization
    state.middleNameHeadOrganization = payload?.middleNameHeadOrganization
    state.office = payload?.office
    state.positionInOrganization = payload?.positionInOrganization
    state.postcode = payload?.postcode
    state.region = payload?.region
    state.shortNameOrganization = payload?.shortNameOrganization
    state.street = payload?.street
    state.unp = payload?.unp
}

const mutations = {
    [ORGANIZATION_REQUEST]: (state) => {
        state.isRequesting = true
    },
    [ORGANIZATION_REQUEST_FAILURE]: (state) => {
        state.isRequesting = false
    },
    [SET_ORGANIZATION]: (state, payload) => {
        setOrganization(state, payload)
        state.isRequesting = false
    },
    [EDIT_ORGANIZATION_REQUEST]: (state) => {
        state.isRequesting = true
    },
    [EDIT_ORGANIZATION_REQUEST_FAILURE]: (state) => {
        state.isRequesting = false
    },
    [EDIT_ORGANIZATION_REQUEST_SUCCESS]: (state, payload) => {
        setOrganization(state, payload)
        state.isRequesting = false
    },
    [SAVE_ORGANIZATION_REQUEST]: (state) => {
        state.isRequesting = true
    },
    [SAVE_ORGANIZATION_REQUEST_FAILURE]: (state) => {
        state.isRequesting = false
    },
    [SAVE_ORGANIZATION_REQUEST_SUCCESS]: (state, payload) => {
        setOrganization(state, payload)
        state.isRequesting = false
    },
    [RESET]: (state) => {
        state.organizationId = null
        state.actingOnBasis = null
        state.bankAccount = null
        state.bankAddress = null
        state.bankIdentificationCode = null
        state.bankName = null
        state.city = null
        state.contactEmail = null
        state.contactFax = null
        state.contactName = null
        state.contactPhoneNumber = null
        state.country = null
        state.firstNameHeadOrganization = null
        state.fullNameHeadOrganization = null
        state.fullNameOrganization = null
        state.house = null
        state.housing = null
        state.lastNameHeadOrganization = null
        state.middleNameHeadOrganization = null
        state.office = null
        state.positionInOrganization = null
        state.postcode = null
        state.region = null
        state.shortNameOrganization = null
        state.street = null
        state.unp = null
        state.isRequesting = false
    }
}

export default mutations
