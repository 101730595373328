const SET_IMPERSONALIZATION_MODE_ON_REQUEST = 'SET_IMPERSONALIZATION_MODE_ON_REQUEST'
const SET_IMPERSONALIZATION_MODE_ON_REQUEST_SUCCESS = 'SET_IMPERSONALIZATION_MODE_ON_REQUEST_SUCCESS'
const SET_IMPERSONALIZATION_MODE_ON_REQUEST_FAILURE = 'SET_IMPERSONALIZATION_MODE_ON_REQUEST_FAILURE'
const SET_IMPERSONALIZATION_MODE_OFF = 'SET_IMPERSONALIZATION_MODE_OFF'


export {
    SET_IMPERSONALIZATION_MODE_ON_REQUEST,
    SET_IMPERSONALIZATION_MODE_ON_REQUEST_SUCCESS,
    SET_IMPERSONALIZATION_MODE_ON_REQUEST_FAILURE,
    SET_IMPERSONALIZATION_MODE_OFF,
}