import {
    SET_LINE_HEIGHTS_TYPES,
    SET_PAGINATION_TYPES,
    SET_PLACES_TYPES,
    SET_PURCHASES_TYPES,
    SET_SUBSCRIPTION_STATUS_TYPES,
    SET_SECTORS_TYPES,
} from "./mutation-types"

const mutations = {
    [SET_PAGINATION_TYPES]: (state, payload) => {
        state.paginationTypes = payload
    },

    [SET_PURCHASES_TYPES]: (state, payload) => {
        state.purchasesTypes = payload
    },

    [SET_PLACES_TYPES]: (state, payload) => {
        state.placesTypes = payload
    },

    [SET_LINE_HEIGHTS_TYPES]: (state, payload) => {
        state.lineHeightsTypes = payload
    },
    
    [SET_SUBSCRIPTION_STATUS_TYPES]: (state, payload) => {
        state.subscriptionStatusesTypes = payload
    },
    [SET_SECTORS_TYPES]: (state, payload) => {
        state.sectorsTypes = payload
    },
}

export default mutations