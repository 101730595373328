import axiosInstance from '@/axios'

const downloadFileForLot = (params) => {
    return axiosInstance.get(`/api/parsing/proposalDocuments`,{
        responseType: 'blob',
        params: {
            ...params,
            source: 'beloil'
        }
    })
}

export {
    downloadFileForLot,
}