import differenceInSeconds from "date-fns/differenceInSeconds"

const getters = {
    profileId: (state) => state.profileId,
    phone: (state) => state.phone,
    daysTillEndOfSubscription: (state) => Math.ceil(
        differenceInSeconds(new Date(state.subscriptionEndDate), new Date()) / (24 * 3600)
    ),
    subscriptionEndDate: (state) => state.subscriptionEndDate,
    hintsEnabled: (state) => state.hintsEnabled,
}

export default getters
