import {last, head, find, nth} from "lodash"

const getters = {
    filterTabs: (state) => state.filterTabs,
    lastFilterTab: (state) => last(state.filterTabs),
    firstFilterTab: (state) => head(state.filterTabs),
    filterTabByParam: (state) => (param, value) => find(state.filterTabs, {[param]: value}),
    currentTab: (state) => state.currentTab,
    filterTabByIndex: (state) => (idx) => nth(state.filterTabs, idx),
}

export default getters