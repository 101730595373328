import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import purchases from './modules/purchases'
import purchasesTableSettings from './modules/purchasesTableSettings'
import enums from './modules/enums'
import filterTabs from './modules/filterTabs'
import organization from './modules/organization'
import profile from './modules/profile'
import columnsFilters from './modules/columnsFilters'
import users from './modules/users'
import columnsSort from './modules/columnsSort'
import impersonalization from './modules/impersonalization'
import subscriptions from './modules/subscriptions'
import purchasesScroll from './modules/purchasesScroll'
import parsers from './modules/parsers'
import proxies from './modules/proxies'
import notifications from './modules/notifications'
import {RESET} from "./mutation-types"

Vue.use(Vuex)

export default new Vuex.Store({

    modules: {
        auth,
        purchases,
        purchasesTableSettings,
        enums,
        filterTabs,
        organization,
        profile,
        notifications,
        columnsFilters,
        users,
        columnsSort,
        impersonalization,
        subscriptions,
        purchasesScroll,
        parsers,
        proxies,
    },

    actions: {
        clearAll({commit}) {
            commit(`auth/${RESET}`)
            commit(`purchases/${RESET}`)
            commit(`purchasesTableSettings/${RESET}`)
            commit(`filterTabs/${RESET}`)
            commit(`organization/${RESET}`)
            commit(`profile/${RESET}`)
            commit(`notifications/${RESET}`)
            commit(`users/${RESET}`)
            commit(`columnsFilters/${RESET}`)
            commit(`columnsSort/${RESET}`)
            commit(`impersonalization/${RESET}`)
            commit(`parsers/${RESET}`)
            commit(`proxies/${RESET}`)
            commit(`subscriptions/${RESET}`)
        }
    }

})
