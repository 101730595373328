export const emailNotificationsPath = 'email'

const displayName = 'Email рассылка'

export default {
    name: 'emailNotifications',
    display: 'Email рассылка',
    path: emailNotificationsPath,
    meta: {
        layout: 'main',
        requiresAuth: true,
        title: displayName,
    },
    component: () => import("@/views/Notifications/components/EmailNotifications")
}