import {
    SET_LINE_HEIGHTS_TYPES,
    SET_PAGINATION_TYPES,
    SET_PLACES_TYPES,
    SET_PURCHASES_TYPES,
    SET_SECTORS_TYPES,
    SET_SUBSCRIPTION_STATUS_TYPES
} from "./mutation-types"
import api from "@/application"
import {map} from "lodash"

const actions = {
    getPaginationTypes: async ({commit}) => {

        try {
            const {data} = await api.enums.getPaginationSettings()
            commit(
                SET_PAGINATION_TYPES,
                map(data._embedded.paginationSettings, it => ({ id: it.id, value: it.value}))
            )
        } catch (e) {
            throw new Error(e)
        }

    },

    getProceduresTypes: async ({commit}) => {

        try {
            const {data} = await api.enums.getProceduresTypesEnum()
            commit(SET_PURCHASES_TYPES, data)
        } catch (e) {
            throw new Error(e)
        }
    },

    getPlacesTypes: async ({commit}) => {

        try {
            const {data} = await api.enums.getSourcesEnum()
            commit(SET_PLACES_TYPES, data)
        } catch (e) {
            throw new Error(e)
        }
    },

    getSectorsTypes: async ({commit}) => {

        try {
            const {data} = await api.enums.getSectorsEnum()
            commit(SET_SECTORS_TYPES, data)
        } catch (e){
            throw new Error(e)
        }

    },

    getLineHeightsTypes: async ({commit}) => {

        try {
            const {data} = await api.enums.getRowTypeSettings()
            commit(
                SET_LINE_HEIGHTS_TYPES,
                map(data._embedded.rowTypeSettings, it => ({ id: it.id, value: it.value, height: it.height}))
            )
        } catch (e) {
            throw new Error(e)
        }
    },
    
    getSubscriptionStatusesTypes: async ({commit}) => {
        
        try {
            const {data} = await api.enums.getSubscriptionStatusesEnum()
            commit(
                SET_SUBSCRIPTION_STATUS_TYPES,
                map(data._embedded.subscriptionStatusDropdowns, it => ({id: it.id, value: it.value}))

            )
        } catch (e) {
            throw new Error(e)
        }
    }
}

export default actions