const state = {
    lineHeightsType: null,
    pagination: null,
    purchasesTableColumns: null,
    tableSettingId: null,
    isActiveSearch: null,
    isKeywordShown: null,
    isRequesting: false,
}

export default state
