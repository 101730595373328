export const historyNotificationsPath = 'history'

const displayName = 'История уведомлений'

export default {
    name: 'historyNotifications',
    display: 'История уведомлений',
    path: historyNotificationsPath,
    meta: {
        layout: 'main',
        requiresAuth: true,
        title: displayName,
    },
    component: () => import("@/views/Notifications/components/HistoryNotifications")
}