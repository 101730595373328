export const LoginModalProperties = {
    class: 'dark',
    title: 'Вход в систему',
    width: 352
}
export const RegistrationModalProperties = {
    class: 'dark',
    title: 'Регистрация',
    width: 370
}
export const SuccessModalProperties = {
    class: 'dark',
    title: '',
    width: 352
}
export const PurchasesTableCustomFilterModalProperties = {
    class: 'light',
    title: 'Создать фильтр',
    width: 1000,
    modalBodyNoPaddings: true
}
export const PurchasesTableEditCustomFilterModalProperties = {
    class: 'light',
    title: 'Редактировать фильтр',
    width: 1000,
    modalBodyNoPaddings: true
}
export const SubscriptionModalProperties = {
    class: 'light',
    title: 'Продление подписки',
    width: 1032,
    modalBodyNoPaddings: true
}
export const ChangePasswordModalProperties = {
    class: 'light',
    title: 'Сменить пароль',
    width: 370
}
export const OtherLotsModalProperties = {
    class: 'light',
    title: 'Другие лоты в закупке',
    width: 470,
    modalBodyNoPaddings: true
}
export const RecoveryPasswordModalProperties = {
    class: 'dark',
    title: 'Восстановить пароль',
    width: 352
}
export const ConfirmModalProperties = {
    class: 'dark',
    title: '',
    width: 352
}
export const SubscriptionHistoryModalProperties = {
    class: 'light',
    title: 'История подписок',
    width: 1200,
}
export const SubscriptionDateModalProperties = {
    class: 'light',
    title: 'Продлить подписку',
    width: 502,
    modalBodyCustomPaddings: true,
}
export const ProxyModalProperties = {
    class: 'light',
    title: 'Список прокси',
    width: 1032,
}