export const subscriptionPath = 'subscription'

const displayName = 'Подписка'

export default {
    name: 'subscription',
    display: 'Подписка',
    path: subscriptionPath,
    meta: {
        layout: 'main',
        requiresAuth: true,
        title: displayName,
    },
    component: () => import("@/views/ProfileTab/components/Subscription")
}