export const privacyPolicyPath = '/privacy-policy'

const displayName = 'Политика конфиденциальности'

export default {
    path: privacyPolicyPath,
    name: 'privacyPolicy',
    meta: {
        layout: 'privacy-policy-and-term-of-use',
        title: displayName
    },
    component: () => import("@/views/PrivacyPolicy")
}