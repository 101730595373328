const CHANGE_COLUMN_PROPERTY_REQUEST = 'CHANGE_COLUMN_PROPERTY_REQUEST'
const CHANGE_COLUMN_PROPERTY_REQUEST_SUCCESS = 'CHANGE_COLUMN_PROPERTY_REQUEST_SUCCESS'
const CHANGE_COLUMN_PROPERTY_REQUEST_FAILURE = 'CHANGE_COLUMN_PROPERTY_REQUEST_FAILURE'
const CHANGE_PAGINATION_SIZE_REQUEST = 'CHANGE_PAGINATION_SIZE_REQUEST'
const CHANGE_PAGINATION_SIZE_REQUEST_SUCCESS = 'CHANGE_PAGINATION_SIZE_REQUEST_SUCCESS'
const CHANGE_PAGINATION_SIZE_REQUEST_FAILURE = 'CHANGE_PAGINATION_SIZE_REQUEST_FAILURE'
const CHANGE_LINE_HEIGHTS_TYPE_REQUEST = 'CHANGE_LINE_HEIGHTS_TYPE_REQUEST'
const CHANGE_LINE_HEIGHTS_TYPE_REQUEST_SUCCESS = 'CHANGE_LINE_HEIGHTS_TYPE_REQUEST_SUCCESS'
const CHANGE_LINE_HEIGHTS_TYPE_REQUEST_FAILURE = 'CHANGE_LINE_HEIGHTS_TYPE_REQUEST_FAILURE'
const UPDATE_TABLE_SETTING_REQUEST = 'UPDATE_TABLE_SETTING_REQUEST'
const UPDATE_TABLE_SETTING_REQUEST_SUCCESS = 'UPDATE_TABLE_SETTING_REQUEST_SUCCESS'
const UPDATE_TABLE_SETTING_REQUEST_FAILURE = 'UPDATE_TABLE_SETTING_REQUEST_FAILURE'
const GET_PURCHASES_TABLE_SETTING_REQUEST = 'GET_PURCHASES_TABLE_SETTING_REQUEST'
const GET_PURCHASES_TABLE_SETTING_REQUEST_SUCCESS = 'GET_PURCHASES_TABLE_SETTING_REQUEST_SUCCESS'
const GET_PURCHASES_TABLE_SETTING_REQUEST_FAILURE = 'GET_PURCHASES_TABLE_SETTING_REQUEST_FAILURE'
const UPDATE_IS_ACTIVE_SEARCH_REQUEST = 'UPDATE_IS_ACTIVE_SEARCH_REQUEST'
const UPDATE_IS_ACTIVE_SEARCH_REQUEST_SUCCESS = 'UPDATE_IS_ACTIVE_SEARCH_REQUEST_SUCCESS'
const UPDATE_IS_ACTIVE_SEARCH_REQUEST_FAILURE = 'UPDATE_IS_ACTIVE_SEARCH_REQUEST_FAILURE'
const UPDATE_IS_KEYWORD_SHOWN_REQUEST = 'UPDATE_IS_KEYWORD_SHOWN_REQUEST'
const UPDATE_IS_KEYWORD_SHOWN_REQUEST_SUCCESS = 'UPDATE_IS_KEYWORD_SHOWN_REQUEST_SUCCESS'
const UPDATE_IS_KEYWORD_SHOWN_REQUEST_FAILURE = 'UPDATE_IS_KEYWORD_SHOWN_REQUEST_FAILURE'

export {
    CHANGE_COLUMN_PROPERTY_REQUEST,
    CHANGE_COLUMN_PROPERTY_REQUEST_SUCCESS,
    CHANGE_COLUMN_PROPERTY_REQUEST_FAILURE,
    CHANGE_PAGINATION_SIZE_REQUEST,
    CHANGE_PAGINATION_SIZE_REQUEST_SUCCESS,
    CHANGE_PAGINATION_SIZE_REQUEST_FAILURE,
    CHANGE_LINE_HEIGHTS_TYPE_REQUEST,
    CHANGE_LINE_HEIGHTS_TYPE_REQUEST_SUCCESS,
    CHANGE_LINE_HEIGHTS_TYPE_REQUEST_FAILURE,
    UPDATE_TABLE_SETTING_REQUEST,
    UPDATE_TABLE_SETTING_REQUEST_SUCCESS,
    UPDATE_TABLE_SETTING_REQUEST_FAILURE,
    GET_PURCHASES_TABLE_SETTING_REQUEST,
    GET_PURCHASES_TABLE_SETTING_REQUEST_SUCCESS,
    GET_PURCHASES_TABLE_SETTING_REQUEST_FAILURE,
    UPDATE_IS_ACTIVE_SEARCH_REQUEST,
    UPDATE_IS_ACTIVE_SEARCH_REQUEST_SUCCESS,
    UPDATE_IS_ACTIVE_SEARCH_REQUEST_FAILURE,
    UPDATE_IS_KEYWORD_SHOWN_REQUEST,
    UPDATE_IS_KEYWORD_SHOWN_REQUEST_SUCCESS,
    UPDATE_IS_KEYWORD_SHOWN_REQUEST_FAILURE,
}