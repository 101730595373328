import {
    CREATE_PROXY_REQUEST,
    CREATE_PROXY_REQUEST_SUCCESS,
    CREATE_PROXY_REQUEST_FAILURE,
    DELETE_PROXY_REQUEST,
    DELETE_PROXY_REQUEST_SUCCESS,
    DELETE_PROXY_REQUEST_FAILURE,
    EDIT_PROXY_REQUEST,
    EDIT_PROXY_REQUEST_SUCCESS,
    EDIT_PROXY_REQUEST_FAILURE,
    GET_PROXIES_REQUEST,
    GET_PROXIES_REQUEST_SUCCESS,
    GET_PROXIES_REQUEST_FAILURE,
} from './mutation-types'
import api from "@/application"

const actions = {
    
    createProxy: async ({commit}, proxyData) => {
        commit(CREATE_PROXY_REQUEST)
        
        try {
            await api.proxies.createProxy(proxyData)
            commit(CREATE_PROXY_REQUEST_SUCCESS)
        } catch (e) {
            commit(CREATE_PROXY_REQUEST_FAILURE)
            let errorText = e.response.data.cause.cause.message
            if (errorText.includes('Duplicate entry')) {
                errorText = 'Данный IP-адресс уже добавлен'
            }
            throw new Error(errorText)
        }
    },
    
    editProxy: async ({commit}, {proxyId, proxyData}) => {
        commit(EDIT_PROXY_REQUEST)
        
        try {
            await api.proxies.editProxy(proxyId, proxyData)
            commit(EDIT_PROXY_REQUEST_SUCCESS)
        } catch (e) {
            commit(EDIT_PROXY_REQUEST_FAILURE)
            let errorText = e.response.data.cause.cause.message
            if (errorText.includes('Duplicate entry')) {
                errorText = 'Данный IP-адресс уже добавлен'
            }
            throw new Error(errorText)
        }
    },
    
    getProxies: async ({commit}) => {
        commit(GET_PROXIES_REQUEST)
        
        try {
            const {data} = await api.proxies.getProxies()
            commit(GET_PROXIES_REQUEST_SUCCESS, data)
        } catch (e) {
            commit(GET_PROXIES_REQUEST_FAILURE)
            throw new Error(e.response.data.errorMessage)
        }
    },
    
    deleteProxy: async ({commit}, proxyId) => {
        commit(DELETE_PROXY_REQUEST)
        
        try {
            await api.proxies.deleteProxy(proxyId)
            commit(DELETE_PROXY_REQUEST_SUCCESS)
        } catch (e) {
            commit(DELETE_PROXY_REQUEST_FAILURE)
            throw new Error(e.response.data.errorMessage)
        }
    }
}

export default actions