import {
    CREATE_SUBSCRIPTION_REQUEST,
    CREATE_SUBSCRIPTION_REQUEST_SUCCESS,
    CREATE_SUBSCRIPTION_REQUEST_FAILURE,
    DELETE_SUBSCRIPTION_REQUEST,
    DELETE_SUBSCRIPTION_REQUEST_SUCCESS,
    DELETE_SUBSCRIPTION_REQUEST_FAILURE,
    EDIT_SUBSCRIPTION_REQUEST,
    EDIT_SUBSCRIPTION_REQUEST_SUCCESS,
    EDIT_SUBSCRIPTION_REQUEST_FAILURE,
    GET_SUBSCRIPTIONS_REQUEST,
    GET_SUBSCRIPTIONS_REQUEST_SUCCESS,
    GET_SUBSCRIPTIONS_REQUEST_FAILURE,
} from './mutation-types'
import api from "@/application"
import {RESET} from "../../mutation-types"

const actions = {

    createSubscription: async ({commit}, subscriptionData) => {
        commit(CREATE_SUBSCRIPTION_REQUEST)

        try {
            await api.subscriptions.createSubscription(subscriptionData)
            commit(CREATE_SUBSCRIPTION_REQUEST_SUCCESS)
        } catch (e) {
            commit(CREATE_SUBSCRIPTION_REQUEST_FAILURE)
            throw new Error(e.response.data.errorMessage)
        }
    },

    editSubscription: async ({commit}, {subscriptionId, subscriptionData}) => {
        commit(EDIT_SUBSCRIPTION_REQUEST)

        try {
            await api.subscriptions.editSubscription(subscriptionId, subscriptionData)
            commit(EDIT_SUBSCRIPTION_REQUEST_SUCCESS)
        } catch (e) {
            commit(EDIT_SUBSCRIPTION_REQUEST_FAILURE)
            throw new Error(e.response.data.errorMessage)
        }
    },

    getSubscriptions: async ({commit}, profileId) => {
        commit(GET_SUBSCRIPTIONS_REQUEST)

        try {
            const {data} = await api.subscriptions.getProfileSubscriptions(profileId)
            commit(GET_SUBSCRIPTIONS_REQUEST_SUCCESS, data)
        } catch (e) {
            commit(GET_SUBSCRIPTIONS_REQUEST_FAILURE)
            throw new Error(e.response.data.errorMessage)
        }
    },

    deleteSubscription: async ({commit}, subscriptionId) => {
        commit(DELETE_SUBSCRIPTION_REQUEST)

        try {
            await api.subscriptions.deleteSubscription(subscriptionId)
            commit(DELETE_SUBSCRIPTION_REQUEST_SUCCESS)
        } catch (e) {
            commit(DELETE_SUBSCRIPTION_REQUEST_FAILURE)
            throw new Error(e.response.data.errorMessage)
        }
    },

    resetSubscriptions: ({commit}) => {

        commit(RESET)
    },
}

export default actions