import {
    SET_SCROLL
} from './mutations-types'

const actions = {

    setScroll: ({commit}, scroll) => {

        commit(SET_SCROLL, scroll)
    },

}
export default actions
