const SET_FILTER_TABS = 'SET_FILTER_TABS'
const SAVE_FILTER_TAB_REQUEST = 'SAVE_FILTER_TAB_REQUEST'
const SAVE_FILTER_TAB_REQUEST_SUCCESS = 'SAVE_FILTER_TAB_REQUEST_SUCCESS'
const SAVE_FILTER_TAB_REQUEST_FAILURE = 'SAVE_FILTER_TAB_REQUEST_FAILURE'
const DELETE_FILTER_TAB_REQUEST = 'DELETE_FILTER_TAB_REQUEST'
const DELETE_FILTER_TAB_SUCCESS = 'DELETE_FILTER_TAB_SUCCESS'
const DELETE_FILTER_TAB_FAILURE = 'DELETE_FILTER_TAB_FAILURE'
const SET_CURRENT_TAB = 'SET_CURRENT_TAB'
const EDIT_FILTER_TAB_REQUEST = 'EDIT_FILTER_TAB_REQUEST'
const EDIT_FILTER_TAB_REQUEST_SUCCESS = 'EDIT_FILTER_TAB_REQUEST_SUCCESS'
const EDIT_FILTER_TAB_REQUEST_FAILURE = 'EDIT_FILTER_TAB_REQUEST_FAILURE'
const UPDATE_ALL_FILTER_TABS_REQUEST = 'UPDATE_ALL_FILTER_TABS_REQUEST'
const UPDATE_ALL_FILTER_TABS_REQUEST_SUCCESS = 'UPDATE_ALL_FILTER_TABS_REQUEST_SUCCESS'
const UPDATE_ALL_FILTER_TABS_REQUEST_FAILURE = 'UPDATE_ALL_FILTER_TABS_REQUEST_FAILURE'
const SOCKET_UPDATE_TAB_FILTER = 'SOCKET_UPDATE_TAB_FILTER'

export {
    SET_FILTER_TABS,
    SAVE_FILTER_TAB_REQUEST,
    SAVE_FILTER_TAB_REQUEST_SUCCESS,
    SAVE_FILTER_TAB_REQUEST_FAILURE,
    DELETE_FILTER_TAB_REQUEST,
    DELETE_FILTER_TAB_SUCCESS,
    DELETE_FILTER_TAB_FAILURE,
    SET_CURRENT_TAB,
    EDIT_FILTER_TAB_REQUEST,
    EDIT_FILTER_TAB_REQUEST_SUCCESS,
    EDIT_FILTER_TAB_REQUEST_FAILURE,
    UPDATE_ALL_FILTER_TABS_REQUEST,
    UPDATE_ALL_FILTER_TABS_REQUEST_SUCCESS,
    UPDATE_ALL_FILTER_TABS_REQUEST_FAILURE,
    SOCKET_UPDATE_TAB_FILTER,
}