import api from "@/application"
import {
    USERS_REQUEST,
    USERS_REQUEST_FAILURE,
    USERS_REQUEST_SUCCESS,
    DELETE_USER_REQUEST,
    DELETE_USER_REQUEST_FAILURE,
    DELETE_USER_REQUEST_SUCCESS,
    CHANGE_USER_STATUS_REQUEST,
    CHANGE_USER_STATUS_REQUEST_SUCCESS,
    CHANGE_USER_STATUS_REQUEST_FAILURE,
    ADD_USER_ROLE_REQUEST,
    ADD_USER_ROLE_REQUEST_SUCCESS,
    ADD_USER_ROLE_REQUEST_FAILURE,
    DELETE_USER_ROLE_REQUEST,
    DELETE_USER_ROLE_REQUEST_SUCCESS,
    DELETE_USER_ROLE_REQUEST_FAILURE,
    SORT_USER_BY_ONLINE,
    MANAGERS_LIST_REQUEST,
    MANAGER_USERS_REQUEST,
    MANAGERS_LIST_REQUEST_SUCCESS,
    MANAGERS_LIST_REQUEST_FAILURE,
    SET_MANAGER_TO_USER,
    SET_MANAGER_TO_USER_SUCCESS,
    SET_MANAGER_TO_USER_FAILURE,
    DELETE_MANAGER_FROM_USER_SUCCESS,
    DELETE_MANAGER_FROM_USER_FAILURE,
    DELETE_MANAGER_FROM_USER
} from "./mutation-types"

const actions = {

    getUsers: async ({commit}, searchParams) => {
        commit(USERS_REQUEST)
        try {
            const {data} = await api.users.getUsers(searchParams)
            commit(USERS_REQUEST_SUCCESS, data)
        } catch (e) {
            commit(USERS_REQUEST_FAILURE)
            throw new Error(e.response.data.errorMessage)
        }
    },

    getLinkedToManagerUsers: async ({commit}, params) => {
        commit(MANAGER_USERS_REQUEST)
        try {
            const {data} = await api.users.getLinkedToManagerUsers(params)
            commit(USERS_REQUEST_SUCCESS, data)
        } catch (e) {
            commit(USERS_REQUEST_FAILURE)
            throw new Error(e.response.data.errorMessage)
        }
    },

    setManagerToUser: async({commit}, params) => {
        commit(SET_MANAGER_TO_USER)
        try {
            await api.set.setManagerToUser(params);
            commit(SET_MANAGER_TO_USER_SUCCESS);     
        } catch (error) {
            commit(SET_MANAGER_TO_USER_FAILURE)
            throw new Error(error.response.data.errorMessage)
        }
    },

    getManagers: async ({commit}) => {
        commit(MANAGERS_LIST_REQUEST)
        try {
            const {data} = await api.users.getManagersList();
            commit(MANAGERS_LIST_REQUEST_SUCCESS, data)
        } catch (e) {
            commit(MANAGERS_LIST_REQUEST_FAILURE)
            throw new Error(e.response.data.errorMessage)
        }
    },

    deleteUser: async ({commit}, email) => {
        commit(DELETE_USER_REQUEST)
        try {
            await api.users.deleteUser(email)
            commit(DELETE_USER_REQUEST_SUCCESS)
        } catch (e) {
            commit(DELETE_USER_REQUEST_FAILURE)
            throw new Error(e.response.data.errorMessage)
        }
    },

    updateUser: async ({commit}, user) => {
        commit(CHANGE_USER_STATUS_REQUEST)

        try {
            await api.users.updateUser(user.email, user)
            commit(CHANGE_USER_STATUS_REQUEST_SUCCESS)
        } catch (e) {
            commit(CHANGE_USER_STATUS_REQUEST_FAILURE)
            throw new Error(e.response.data.errorMessage)
        }
    },

    changeUserRole: async ({commit}, {currentId, roleId, email}) => {
        await api.roles.deleteRole(currentId, email).then(async () => {
            await api.roles.addRole(roleId, email);
            commit(ADD_USER_ROLE_REQUEST_SUCCESS)
        }).catch((e) => {
            commit(ADD_USER_ROLE_REQUEST_FAILURE)
            throw new Error(e.response.data.errorMessage)
        });
    },

    addUserRole: async ({commit}, {roleId, email}) => {
        commit(ADD_USER_ROLE_REQUEST)
        try {
            await api.roles.addRole(roleId, email)
            commit(ADD_USER_ROLE_REQUEST_SUCCESS)
        } catch (e) {
            commit(ADD_USER_ROLE_REQUEST_FAILURE)
            throw new Error(e.response.data.errorMessage)
        }
    },

    removeUserRole: async ({commit}, {roleId, email}) => {
        commit(DELETE_USER_ROLE_REQUEST)

        try {
            await api.roles.deleteRole(roleId, email)
            commit(DELETE_USER_ROLE_REQUEST_SUCCESS)
        } catch (e) {
            commit(DELETE_USER_ROLE_REQUEST_FAILURE)
            throw new Error(e.response.data.errorMessage)
        }
    },

    sortUsersByOnline: async ({commit}, {size, isOnline, role, page = 0}) => {
        commit(SORT_USER_BY_ONLINE);
        try {
            const {data} = await api.sort.sortUsersByOnline(size, isOnline, role, page);
            commit(USERS_REQUEST_SUCCESS, data)
        } catch (e) {
            commit(USERS_REQUEST_FAILURE)
            throw new Error(e.response.data.errorMessage)
        }
    },

    deleteManagerFromUser: async ({commit}, {manager, email}) => {
        commit(DELETE_MANAGER_FROM_USER);
        try {
            await api.users.deleteManagerFromUser(manager, email)
            commit(DELETE_MANAGER_FROM_USER_SUCCESS)
        } catch (e) {
            commit(DELETE_MANAGER_FROM_USER_FAILURE)
            throw new Error(e.response.data.errorMessage)
        }
    },

}

export default actions