const LOGS_REQUEST = 'LOGS_REQUEST'
const LOGS_REQUEST_FAILURE = 'LOGS_REQUEST_FAILURE'
const LOGS_REQUEST_SUCCESS = 'LOGS_REQUEST_SUCCESS'
const DELETE_LOGS_BY_SOURCE_REQUEST = 'DELETE_LOGS_BY_SOURCE_REQUEST'
const DELETE_LOGS_BY_SOURCE_REQUEST_SUCCESS = 'DELETE_LOGS_BY_SOURCE_REQUEST_SUCCESS'
const DELETE_LOGS_BY_SOURCE_REQUEST_FAILURE = 'DELETE_LOGS_BY_SOURCE_REQUEST_FAILURE'

export {
    LOGS_REQUEST,
    LOGS_REQUEST_FAILURE,
    LOGS_REQUEST_SUCCESS,
    DELETE_LOGS_BY_SOURCE_REQUEST,
    DELETE_LOGS_BY_SOURCE_REQUEST_SUCCESS,
    DELETE_LOGS_BY_SOURCE_REQUEST_FAILURE,
}