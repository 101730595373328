import {
    DELETE_FILTER_TAB_FAILURE,
    DELETE_FILTER_TAB_REQUEST,
    DELETE_FILTER_TAB_SUCCESS,
    EDIT_FILTER_TAB_REQUEST,
    EDIT_FILTER_TAB_REQUEST_FAILURE,
    EDIT_FILTER_TAB_REQUEST_SUCCESS,
    SAVE_FILTER_TAB_REQUEST,
    SAVE_FILTER_TAB_REQUEST_FAILURE,
    SAVE_FILTER_TAB_REQUEST_SUCCESS,
    SET_CURRENT_TAB,
    SET_FILTER_TABS,
    UPDATE_ALL_FILTER_TABS_REQUEST,
    UPDATE_ALL_FILTER_TABS_REQUEST_SUCCESS,
    UPDATE_ALL_FILTER_TABS_REQUEST_FAILURE,
    SOCKET_UPDATE_TAB_FILTER,
} from "./mutation-types"
import {RESET} from "../../mutation-types"
import {findIndex, concat, slice, map, sortBy} from "lodash"

const mutations = {

    [SET_FILTER_TABS]: (state, filters) => {
        state.filterTabs = sortBy(map(filters, (filter) => {
            // eslint-disable-next-line no-unused-vars
            const {_links, ...rest} = filter
            return rest
        }), it => it.tabIndex)
    },

    [SAVE_FILTER_TAB_REQUEST]: (state) => {
        state.isRequesting = true
    },

    [SAVE_FILTER_TAB_REQUEST_SUCCESS]: (state) => {
        state.isRequesting = false
    },

    [SAVE_FILTER_TAB_REQUEST_FAILURE]: (state) => {
        state.isRequesting = false
    },

    [DELETE_FILTER_TAB_REQUEST]: (state) => {
        state.isRequesting = true
    },

    [DELETE_FILTER_TAB_SUCCESS]: (state) => {
        state.isRequesting = false
    },

    [DELETE_FILTER_TAB_FAILURE]: (state) => {
        state.isRequesting = false
    },

    [SET_CURRENT_TAB]: (state, payload) => {
        // eslint-disable-next-line no-unused-vars
        const {_links, ...filterTab} = payload
        state.currentTab = filterTab
        const idx = findIndex(state.filterTabs, it => it.id === filterTab.id)
        state.filterTabs = concat(slice(state.filterTabs, 0, idx), filterTab, slice(state.filterTabs, idx + 1))
    },

    [EDIT_FILTER_TAB_REQUEST]: (state) => {
        state.isRequesting = true
    },

    [EDIT_FILTER_TAB_REQUEST_SUCCESS]: (state, payload) => {
        state.isRequesting = false
        // eslint-disable-next-line no-unused-vars
        const {_links, ...filterTab} = payload
        const idx = findIndex(state.filterTabs, it => it.id === filterTab.id)
        state.filterTabs = concat(slice(state.filterTabs, 0, idx), filterTab, slice(state.filterTabs, idx + 1))
    },

    [EDIT_FILTER_TAB_REQUEST_FAILURE]: (state) => {
        state.isRequesting = false
    },

    [UPDATE_ALL_FILTER_TABS_REQUEST]: (state) => {
        state.isRequesting = true
    },

    [UPDATE_ALL_FILTER_TABS_REQUEST_SUCCESS]: (state, newFilterTabs) => {
        state.isRequesting = false
        state.filterTabs = newFilterTabs
    },

    [UPDATE_ALL_FILTER_TABS_REQUEST_FAILURE]: (state) => {
        state.isRequesting = false
    },

    [SOCKET_UPDATE_TAB_FILTER]: (state, {filterId, newLots}) => {

        const idx = findIndex(state.filterTabs, it => it.id === filterId)
        state.filterTabs = concat(
            slice(state.filterTabs, 0, idx),
            {...state.filterTabs[idx], newLots},
            slice(state.filterTabs, idx + 1)
        )
    },

    [RESET]: (state) => {
        state.filterTabs = []
        state.isRequesting = false
        state.currentTab = null
    },
}

export default mutations