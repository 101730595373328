import getters from "./getters"
import state from "./state"
import actions from "./actions"
import mutations from "./mutations"

export default {
    namespaced: true,
    getters,
    state,
    actions,
    mutations
}