export const myProfilePath = ''

const displayName = 'Мой Профиль'

export default {
    name: 'myProfile',
    display: 'Профиль',
    path: myProfilePath,
    meta: {
        layout: 'main',
        requiresAuth: true,
        title: displayName,
    },
    component: () => import("@/views/ProfileTab/components/MyProfile")
}