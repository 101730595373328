import {RESET} from "../../mutation-types"
import {
    ADD_USER_ROLE_REQUEST, ADD_USER_ROLE_REQUEST_FAILURE, ADD_USER_ROLE_REQUEST_SUCCESS,
    CHANGE_USER_STATUS_REQUEST,
    CHANGE_USER_STATUS_REQUEST_FAILURE,
    CHANGE_USER_STATUS_REQUEST_SUCCESS,
    DELETE_USER_REQUEST,
    DELETE_USER_REQUEST_FAILURE,
    DELETE_USER_REQUEST_SUCCESS,
    USERS_REQUEST,
    USERS_REQUEST_FAILURE,
    USERS_REQUEST_SUCCESS,
    SORT_USER_BY_ONLINE,
    MANAGERS_LIST_REQUEST,
    MANAGERS_LIST_REQUEST_SUCCESS,
    MANAGERS_LIST_REQUEST_FAILURE,
    MANAGER_USERS_REQUEST,
    SET_MANAGER_TO_USER,
    SET_MANAGER_TO_USER_SUCCESS,
    SET_MANAGER_TO_USER_FAILURE,
    DELETE_MANAGER_FROM_USER_SUCCESS,
    DELETE_MANAGER_FROM_USER_FAILURE,
    DELETE_MANAGER_FROM_USER,
} from "./mutation-types"
import {mapValues} from "lodash"

const mutations = {
    [USERS_REQUEST]: (state) => {
        state.isRequesting = true
    },

    [SORT_USER_BY_ONLINE]: (state) => {
        state.isRequesting = true
    },

    [MANAGER_USERS_REQUEST]: (state) => {
        state.isRequesting = true
    },

    [SET_MANAGER_TO_USER]: (state) => {
        state.isRequesting = true
    },

    [SET_MANAGER_TO_USER_SUCCESS]: (state) => {
        state.isRequesting = false
    },

    [SET_MANAGER_TO_USER_FAILURE]: (state) => {
        state.isRequesting = false
    },

    [USERS_REQUEST_FAILURE]: (state) => {
        state.isRequesting = false
    },

    [USERS_REQUEST_SUCCESS]: (state, {_embedded: {userResponses}, _links, page}) => {
        state.users = userResponses
        state.links = mapValues(_links, (v) => new URLSearchParams(new URL(v.href).search.substring(1)).get('page'))
        state.page = page
        state.isRequesting = false
    },

    [MANAGERS_LIST_REQUEST]: (state) => {
        state.isRequesting = true
    },

    [MANAGERS_LIST_REQUEST_FAILURE]: (state) => {
        state.isRequesting = false
    },

    [MANAGERS_LIST_REQUEST_SUCCESS]: (state, {_embedded: {userResponses}, _links, page}) => {
        state.managers = userResponses;
        state.links = mapValues(_links, (v) => new URLSearchParams(new URL(v.href).search.substring(1)).get('page'));
        state.page = page;
        state.isRequesting = false;
    },

    [DELETE_USER_REQUEST]: (state) => {
        state.isRequesting = true
    },
    [DELETE_USER_REQUEST_FAILURE]: (state) => {
        state.isRequesting = false
    },
    [DELETE_USER_REQUEST_SUCCESS]: (state) => {
        state.isRequesting = false
    },

    [CHANGE_USER_STATUS_REQUEST]: (state) => {
        state.isRequesting = true
    },
    [CHANGE_USER_STATUS_REQUEST_FAILURE]: (state) => {
        state.isRequesting = false
    },

    [CHANGE_USER_STATUS_REQUEST_SUCCESS]: (state) => {
        state.isRequesting = false
    },

    [ADD_USER_ROLE_REQUEST]: (state) => {
        state.isRequesting = true
    },
    [ADD_USER_ROLE_REQUEST_SUCCESS]: (state) => {
        state.isRequesting = false
    },

    [DELETE_MANAGER_FROM_USER]: (state) => {
        state.isRequesting = true
    },

    [DELETE_MANAGER_FROM_USER_SUCCESS]: (state) => {
        state.isRequesting = false
    },

    [DELETE_MANAGER_FROM_USER_FAILURE]: (state) => {
        state.isRequesting = false
    },

    [ADD_USER_ROLE_REQUEST_FAILURE]: (state) => {
        state.isRequesting = false
    },

    [DELETE_USER_REQUEST]: (state) => {
        state.isRequesting = true
    },
    [DELETE_USER_REQUEST_SUCCESS]: (state) => {
        state.isRequesting = false
    },

    [DELETE_USER_REQUEST_FAILURE]: (state) => {
        state.isRequesting = false
    },

    [RESET]: (state) => {
        state.users = []
        state.links = {}
        state.page = {}
    }
}

export default mutations