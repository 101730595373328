import store from '@/store'
import SockJS from "sockjs-client"
import Stomp from "webstomp-client"
import Configuration from "@/configuration"

let stompClient = null

const connectWS = () => {
    const url = `${Configuration.value('apiEndpoint')}/api/profile/ws?access_token=${store.getters['auth/accessToken']}`
    const socket = new SockJS(url.toString())
    stompClient = Stomp.over(socket)
    stompClient.debug = () => {}
    stompClient.connect({}, () => {
        stompClient.subscribe('/user/topic/filter', message => {
            if (message && !store.getters['impersonalization/mindControlledUser']) {
                store.dispatch('filterTabs/updateFilterTab', JSON.parse(message.body))
            }
        })
    })
}

const disconnectWS = () => {
    if (stompClient) {
        stompClient.disconnect()
    }
}

export {
    connectWS,
    disconnectWS,
}