import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import {routes} from "@/router/routes"

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior () {
        return { x: 0, y: 0 }
    }
})

router.beforeEach(async (to, from, next) => {

    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (store.getters["auth/isAuthenticated"]) {
            next()
        } else {
            next("/")
        }
    } else {
        next()
    }
})

export default router
