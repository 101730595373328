const REFRESH_TOKEN_KEY = 'refresh_token'

export const getRefreshToken = () => {
    return localStorage.getItem(REFRESH_TOKEN_KEY)
}

export const setRefreshToken = (refreshToken) => {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
}

export const logoutUser = () => {
    clearStorage()
}

export const clearRefreshToken = () => {
    localStorage.removeItem(REFRESH_TOKEN_KEY)
}

export const clearStorage = () => {
    clearRefreshToken()
}
