import * as auth from './requests/authRequests'
import * as profile from './requests/profileRequests'
import * as purchases from './requests/purchasesRequests'
import * as filtersTabs from './requests/filterTabsRequests'
import * as organization from './requests/organizationRequests'
import * as enums from './requests/enumRequests'
import * as users from './requests/usersRequests'
import * as tableSetting from './requests/tableSettingRequests'
import * as mail from './requests/mailRequests'
import * as parsers from './requests/parsersRequests'
import * as subscriptions from './requests/subscriptionsRequests'
import * as pdf from './requests/pdfRequests'
import * as proxies from './requests/proxiesRequests'
import * as roles from './requests/rolesRequests'
import * as columnSetting from './requests/columnSettingRequests'
import * as sort from './requests/sortUsers'
import * as set from './requests/setManagerRequests'

import * as documents from './requests/documentRequests'
import * as notifications from './requests/notificationsRequests'

const api = {
    auth,
    profile,
    purchases,
    filtersTabs,
    organization,
    enums,
    users,
    tableSetting,
    mail,
    parsers,
    subscriptions,
    pdf,
    documents,
    proxies,
    roles,
    columnSetting,
    sort,
    set,
    notifications,
}

export default api
