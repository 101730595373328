import children from "./children"

export const notificationPath = '/notifications'

export default {
    path: notificationPath,
    icon: 'icoMail',
    display: 'Уведомления',
    meta: {
        requiresAuth: true,
    },
    children: children,
    component: () => import("@/views/Notifications/Notifications")
}