import {
    GET_PURCHASES_REQUEST,
    GET_PURCHASES_REQUEST_SUCCESS,
    GET_PURCHASES_REQUEST_FAILURE,
    SET_FAVORITE_PURCHASE,
} from "./mutation-types"
import {RESET} from "../../mutation-types"
import {mapValues, find} from "lodash"

const mutations = {

    [GET_PURCHASES_REQUEST]: (state) => {
        state.isRequesting = true
    },

    [GET_PURCHASES_REQUEST_SUCCESS]: (state, {_embedded: {lotResponses}, _links, page}) => {
        state.purchases = lotResponses
        state.links = mapValues(_links, (v) => new URLSearchParams(new URL(v.href).search.substring(1)).get('page'))
        state.page = page
        state.isRequesting = false
    },

    [GET_PURCHASES_REQUEST_FAILURE]: (state) => {
        state.isRequesting = false
    },

    [SET_FAVORITE_PURCHASE]: (state, favorites) => {
        const favoritesPurchase = find(state.purchases, it => it.favorites.lotId === favorites.lotId)
        favoritesPurchase.favorites.favorite = favorites.favorite
        favoritesPurchase.favorites.lotId = favorites.lotId
        favoritesPurchase.favorites.favoriteId = favorites.favoriteId
    },

    [RESET]: (state) => {
        state.purchases = []
        state.links = {}
        state.page = {}

    }
}
export default mutations
