<template>
    <div class="home-wrapper">
        <header class="header">
            <div class="container">
                <router-link
                        :to="homePath"
                        exact
                >
                    <img src="../assets/img/logo.png" alt="" width="113" height="54">
                </router-link>
            </div>
        </header>
        <router-view/>
    </div>
</template>

<script>
    import {homePath} from "@/router/home"

    export default {
        name: "PrivacyPolicyAndTermOfUseLayout",
        computed: {
            homePath() {
                return homePath
            },
        },
    }
</script>

<style lang="less">
    @import '../assets/less/variables.less';
    @import '../assets/less/bootstrapGrid.less';
    @import '../assets/less/commonStyles.less';
    @import '../assets/less/homeStyles.less';

    .header {
        padding-top: 24px;
        padding-bottom: 24px;

        .container {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }

    @media (max-width: 991px) {
        .header {
            padding-top: 16px;
            padding-bottom: 16px;
        }
    }

    @media (max-width: 575px) {
        .footer {
            &-logo {
                margin: 0 auto 32px;
            }
        }
    }
</style>