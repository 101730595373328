<template>
    <section class="main-section">
        <HamburgerMenu/>
        <div class="mainContainer">
            <router-view/>
        </div>
        <button
                v-if="mindControlledUser"
                @click="exitImpersonalizationMode"
                type="button"
                class="impersonalization-mode-btn btn"
        >
            <div>
                Покинуть режим
            </div>
            <div>
                {{mindControlledUser}}
            </div>
        </button>
    </section>

</template>

<script>
    import HamburgerMenu from "../components/common/HamburgerMenu"
    import {mapActions, mapGetters} from "vuex"
    import {usersPath} from "@/router/users"
    import Vue from "vue"
import { reinitCancelToken, source } from "../application/requests/notificationsRequests"

    export default {
        name: "MainLayout",
        components: {HamburgerMenu},
        computed: {
            ...mapGetters('impersonalization', ['mindControlledUser']),
            ...mapGetters('profile', ['profileId']),
            ...mapGetters('auth', ['authorities']),
        },
        methods: {
            ...mapActions('impersonalization', ['turnOffImpersonalizationMode']),
            ...mapActions('notifications', ['getDisplayedNotifications', 'getAllNotifications', 'getNewNotifications']),
            exitImpersonalizationMode() {
                this.turnOffImpersonalizationMode()
                this.$router.push(usersPath)
                Vue.notify({
                    group: 'templateNotification',
                    clean: true,
                })
                source.cancel()
                reinitCancelToken()
            },

            callNotify(data){
                for(let i = 0; i < 3; i++){
                    Vue.notify({
                        group: 'templateNotification',
                        title: data.header,
                        text: data.content,
                        duration: -1,
                        max: 3,
                        ignoreDuplicates: true,
                        data: {
                            active: data.buttonIncluded,
                            priority: data.priority,
                            notificationId: data.id,
                        }
                    })
                }   
            },

            getPopUpNotifications(id){
                if(!this.authorities.includes('ROLE_ADMIN')){
                    this.getDisplayedNotifications(id).then(data => {
                        if(typeof data == 'string'){
                            this.getPopUpNotifications(id)
                        }else{
                            for(let item of data){
                                this.callNotify(item)
                            }
                            this.getNewNotifications(this.profileId)

                            setTimeout(() => {
                                this.getPopUpNotifications(id)
                            }, 5000)
                        } 
                    })
                }
            }    
        },
        mounted(){
            setTimeout(() => this.getPopUpNotifications(this.profileId), 2000)
        },
    }
</script>
<style lang="less" scoped>
    @import '../assets/less/variables.less';

    .main-section {
        min-height: 100vh;
    }

    .mainContainer {
        margin-left: 64px;
        padding: 16px 24px 0;
        min-height: 100vh;
    }

    .impersonalization-mode-btn {
        position: fixed;
        display: flex;
        flex-direction: column;
        right: 0;
        top: 50%;
        background-color: @error-color;
        z-index: 5100;
    }

</style>
