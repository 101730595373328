<template>
    <div>
        <router-view/>
    </div>

</template>

<script>
    export default {
        name: "BackgroundOnly"
    }
</script>

<style scoped>
    div {
        height: 100vh;
        background-image: url("../assets/img/bg-home-welcome.png");
        background-size: cover;
    }
</style>