export const notFoundPath = '/404'

const displayName = 'Страница не найдена'

export default {
    path: notFoundPath,
    name: 'notFound',
    meta: {
        layout: 'background-only',
        title: displayName,
    },
    component: () => import("@/views/NotFoundPage/NotFound")
}