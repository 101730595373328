export const archiveNotificationsPath = 'archive'

const displayName = 'Архив'

export default {
    name: 'archiveNotifications',
    display: 'Архив',
    path: archiveNotificationsPath,
    meta: {
        layout: 'main',
        requiresAuth: true,
        title: displayName,
    },
    component: () => import("@/views/Notifications/components/ArchiveNotifications")
}