export const usersPath = '/users'

const displayName = 'Пользователи'

export default {
    path: usersPath,
    name: 'users',
    icon: 'icoPeople',
    display: displayName,
    meta: {
        layout: 'main',
        requiresAuth: true,
        permission: ['ROLE_ADMIN', 'ROLE_MANAGER'],
        title: displayName,
    },
    component: () => import("@/views/Users")
}