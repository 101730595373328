import {clearStorage, logoutUser, setRefreshToken} from "@/utils/auth"
import {
    LOGIN_USER_REQUEST,
    LOGIN_USER_REQUEST_SUCCESS,
    LOGIN_USER_REQUEST_FAILURE,
    LOGOUT_USER,
    REFRESH_TOKENS_REQUEST,
    REFRESH_TOKENS_REQUEST_SUCCESS,
    REFRESH_TOKENS_REQUEST_FAILURE,
} from "./mutation-types"
import api from '@/application'
import axiosInstance from "@/axios"

const actions = {
    loginUser: async ({commit, dispatch, rootGetters}, credentials) => {
        commit(LOGIN_USER_REQUEST)
        try {
            const {data} = await api.auth.loginUser(credentials)
            setRefreshToken(data.refresh_token)
            axiosInstance.defaults.headers['Authorization'] = `Bearer ${data.access_token}`
            commit(LOGIN_USER_REQUEST_SUCCESS, data)
            await dispatch('profile/getProfile', rootGetters['auth/email'], {root: true})
            await dispatch('filterTabs/getFilterTabs', rootGetters['profile/profileId'], {root: true})
            await dispatch('purchasesTableSettings/getPurchasesTableSetting', rootGetters['profile/profileId'], {root: true})
            await dispatch('notifications/getAllNotifications', rootGetters['profile/profileId'], {root: true})
            await dispatch('notifications/getNewNotifications', rootGetters['profile/profileId'], {root: true})
            if(rootGetters['auth/authorities'].includes('ROLE_ADMIN')){
                await dispatch('notifications/getHistoryNotifications', '', {root: true})
            }
        } catch (error) {
            clearStorage()
            commit(LOGIN_USER_REQUEST_FAILURE)
            let errorText = null
            let cleanPassword = false
            if (error.response.status >= 500) {
                throw {
                    status: error.response.status
                }
            }
            switch(error.response.data.error_description) {
                case 'Bad credentials':
                    errorText = 'Неверный логин или пароль.'
                    cleanPassword = true
                    break
                case 'User is disabled':
                    errorText = 'Данный пользователь не активирован. Пожалуйста, подтвердите email.'
                    break
            }

            throw {
                cleanPassword,
                message: errorText
            }
        }
    },

    refreshTokens: async ({commit, rootGetters, dispatch}, refreshToken) => {
        commit(REFRESH_TOKENS_REQUEST)

        try {
            const {data} = await api.auth.refreshTokens(refreshToken)
            setRefreshToken(data.refresh_token)
            axiosInstance.defaults.headers['Authorization'] = `Bearer ${data.access_token}`
            commit(REFRESH_TOKENS_REQUEST_SUCCESS, data)
            if(!rootGetters["profile/id"]) {
                await dispatch('profile/getProfile', rootGetters['auth/email'], {root: true})
                await dispatch('filterTabs/getFilterTabs', rootGetters['profile/profileId'], {root: true})
                await dispatch('purchasesTableSettings/getPurchasesTableSetting', rootGetters['profile/profileId'], {root: true})
                await dispatch('notifications/getAllNotifications', rootGetters['profile/profileId'], {root: true})
                await dispatch('notifications/getNewNotifications', rootGetters['profile/profileId'], {root: true})
                if(rootGetters['auth/authorities'].includes('ROLE_ADMIN')){
                    await dispatch('notifications/getHistoryNotifications', '',  {root: true})
                }
            }
        } catch (response) {
            clearStorage()
            commit(REFRESH_TOKENS_REQUEST_FAILURE, response)
            throw new Error(response)
        }
    },

    logoutUser: async ({commit}) => {
        axiosInstance.defaults.headers['Authorization'] = ``
        logoutUser()
        commit(LOGOUT_USER)
    },

}

export default actions
