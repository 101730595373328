const fileDownload = ({headers, data}) => {

    const octetStreamMime = 'application/octet-stream'
    const cd = headers['content-disposition']
    const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    const match = regex.exec(cd)
    const fileName = decodeURI(match[1] ?? 'myDefaultFileName.pdf')
    const contentType = headers['content-type'] ?? octetStreamMime

    try {
        const blob = new Blob([data], {type: contentType})

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileName)
        } else {
            const objectUrl = window.URL.createObjectURL(blob)
            const tempLink = document.createElement('a')
            tempLink.style.display = 'none'
            tempLink.href = objectUrl
            tempLink.setAttribute('download', fileName)

            if (typeof tempLink.download === 'undefined') {
                tempLink.setAttribute('target', '_blank')
            }

            document.body.appendChild(tempLink)
            tempLink.click()
            document.body.removeChild(tempLink)
            window.URL.revokeObjectURL(objectUrl)
        }
    } catch (exc) {
        throw new Error(exc.message)
    }
}

export {
    fileDownload
}