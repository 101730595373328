export const sendNotificationsPath = 'send'

const displayName = 'Отправить уведомление'

export default {
    name: 'sendNotifications',
    display: 'Отправить уведомление',
    path: sendNotificationsPath,
    meta: {
        layout: 'main',
        requiresAuth: true,
        title: displayName,
    },
    component: () => import("@/views/Notifications/components/SendNotifications")
}