export const organizationInfoPath = 'organizationInfo'

const displayName = 'Информация об организации'

export default {
    name: 'organizationInfo',
    display: displayName,
    path: organizationInfoPath,
    meta: {
        layout: 'main',
        requiresAuth: true,
        title: displayName,
    },
    component: () => import("@/views/ProfileTab/components/OrganizationInfo")
}