const SET_COLUMNS_FILTERS = 'SET_COLUMNS_FILTERS'
const ADD_COLUMN_FILTER = 'ADD_COLUMN_FILTER'
const REMOVE_COLUMN_FILTER = 'REMOVE_COLUMN_FILTER'
const REMOVE_COLUMN_FILTERS = 'REMOVE_COLUMN_FILTERS'

export {
    SET_COLUMNS_FILTERS,
    ADD_COLUMN_FILTER,
    REMOVE_COLUMN_FILTER,
    REMOVE_COLUMN_FILTERS,
}