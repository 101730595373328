import axios from 'axios'
import store from "@/store"
import grantTypes from "@/utils/constants/grantTypes"
import Configuration from "@/configuration"

const axiosInstance = axios.create({
    baseURL: Configuration.value('apiEndpoint'),
})

axiosInstance.interceptors.request.use(
    async (config) => {

        return config
    },
    async (error) => {

        return Promise.reject(error)
    }
)

axiosInstance.interceptors.response.use(
    async (response) => {

        return response
    },
    async (error) => {
        const originalRequest = error.config

        const grantType = originalRequest.params?.grant_type
        if (error.response.status === 401 && !originalRequest._retry && grantType !== grantTypes.refresh_token) {
            originalRequest._retry = true

            await store.dispatch('auth/refreshTokens', store.getters["auth/refreshToken"])
            originalRequest.headers = {
                'Authorization': `Bearer ${store.getters["auth/accessToken"]}`
            }

            return axiosInstance(originalRequest)
        }

        return Promise.reject(error)

    }
)

export default axiosInstance