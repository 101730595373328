import {
    PROFILE_REQUEST,
    PROFILE_REQUEST_FAILURE,
    SET_PROFILE,
    UPDATE_PROFILE_PHONE_REQUEST,
    UPDATE_PROFILE_PHONE_REQUEST_FAILURE,
    UPDATE_PROFILE_PHONE_REQUEST_SUCCESS,
    UPDATE_PROFILE_HINTS_ENABLED_REQUEST,
    UPDATE_PROFILE_HINTS_ENABLED_REQUEST_FAILURE,
    UPDATE_PROFILE_HINTS_ENABLED_REQUEST_SUCCESS,
} from "./mutation-types"
import {RESET} from "../../mutation-types"

const mutations = {

    [PROFILE_REQUEST]: (state) => {
        state.isRequesting = true
    },

    [PROFILE_REQUEST_FAILURE]: (state) => {
        state.isRequesting = false
    },

    [SET_PROFILE]: (state, {id, phone, subscriptionEndDate, hintsEnabled}) => {
        state.profileId = id
        state.phone = phone
        state.hintsEnabled = hintsEnabled
        state.subscriptionEndDate = subscriptionEndDate
    },

    [UPDATE_PROFILE_PHONE_REQUEST]: (state) => {
        state.isRequesting = true
    },

    [UPDATE_PROFILE_PHONE_REQUEST_FAILURE]: (state) => {
        state.isRequesting = false
    },

    [UPDATE_PROFILE_PHONE_REQUEST_SUCCESS]: (state, phone) => {
        state.phone = phone
        state.isRequesting = false
    },

    [UPDATE_PROFILE_HINTS_ENABLED_REQUEST]: (state) => {
        state.isRequesting = true
    },

    [UPDATE_PROFILE_HINTS_ENABLED_REQUEST_FAILURE]: (state) => {
        state.isRequesting = false
    },

    [UPDATE_PROFILE_HINTS_ENABLED_REQUEST_SUCCESS]: (state, hintsEnabled) => {
        state.hintsEnabled = hintsEnabled
        state.isRequesting = false
    },

    [RESET]: (state) => {
        state.isRequesting = false
    }
}

export default mutations
